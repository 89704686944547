import {  Table, Button, Icon, Message, Container } from "semantic-ui-react";
import {  NavLink } from 'react-router-dom'
import {   useHistory } from 'react-router-dom'
import OrgNeedHeadRow from "./OrgNeedHeadRow";

const OrgNeedsTable = (props) => {
    console.log('OrgNeedsTable')

    const myneeds = props.myneeds
    const id = props.id
    const packagename=props.packagename
    const packageexpired=props.packageexpired

    const navigateto = (nid) => {
        //console.log(nid) 
        history.push(`/packages/${id}/mypublish/${nid}`)
    }

    const history = useHistory()
    
    console.log('needs', myneeds)
    return myneeds.length > 0? (

        <div style={{marginLeft:'2em', marginRight:'2em', marginBottom:'3em'}}>
      
            <h3>My published needs for <b>{packagename}</b></h3>
        
            <Table selectable>
                  <Table.Header>
                    <OrgNeedHeadRow />
                  </Table.Header>
        
                  <Table.Body >
                    {myneeds.map(n => { 
                      return (
                    <Table.Row style={{cursor:'pointer'}}>
                      <Table.Cell  >{n.creationDate}</Table.Cell>
                      <Table.Cell>{n.country}</Table.Cell>                            
                      <Table.Cell>{n.region==null?'All regions':n.region}</Table.Cell>
                      <Table.Cell>{n.custtype}</Table.Cell>
                      <Table.Cell>{n.promotion}</Table.Cell>
                      <Table.Cell>{n.promotiontool ? n.promotiontool: '---'}</Table.Cell>
                      <Table.Cell>{n.promotiontoolspec ? n.promotiontoolspec:'---'}</Table.Cell>
                      <Table.Cell>{n.minbudget.toLocaleString('en-US')}</Table.Cell>
                      <Table.Cell>{n.maxbudget.toLocaleString('en-US')}</Table.Cell>
                      <Table.Cell>{n.from}</Table.Cell>              
                      <Table.Cell>{n.to}</Table.Cell>              
                      <Table.Cell style={ {color:n.active?'green':'red'}}>{n.active?'ACTIVE':'DISABLED'}</Table.Cell>              
                      <Table.Cell>  
                        <Button disabled={packageexpired} onClick={() => navigateto(n.id)} basic color='black'>View</Button>                        

                        <Button disabled={packageexpired} animated basic color='black'>
                          <Button.Content visible>Edit</Button.Content>
                          <Button.Content as={NavLink} to={`/packages/${id}/mypublish/${n.id}/edit`} hidden>
                            <Icon name='edit' />
                          </Button.Content>
                        </Button>                
                      </Table.Cell>
                  </Table.Row>              
                  )
                    } )}
                    </Table.Body>
              </Table>        
        </div>        
    ):
    <Container>
      <Message
        warning
        icon='warning'
        header='No needs found attached to this package'
        // content={'Form contains errors'}
      />
   </Container>
}

export default OrgNeedsTable;