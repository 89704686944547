import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Message, Dimmer, Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";


import SegmentOverview from "./modules/SegmentOverview";
import SegmentSocial from "./modules/SegmentSocial";
import SegmentContact from "./modules/SegmentContact";

const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;
    case UPDATE_STATE:{
      console.log('vvvv', event.value)
      return { ...profile, [event.name]: event.value }
    }
    default:
      return profile;
  }  

 }

 const initialState = {};


const ProfileContractorForm = () => {
  console.log('RENDER ProfileContractorForm')
  
  const [profile, dispatch] = useReducer(formReducer, initialState);
  const [profileloader, setProfileloader] = useState(false)
  const [user, setUser] = useState()
  const [loader, setLoader] = useState(false)
  const history = useHistory();
  const [services, setServices] = useState([])

  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])

  const [errorname, setErrorname]= useState()
  const [errorlogo, setErrorlogo] = useState()
  
  
  const [errorservice, setErrorservice] = useState()
  const [errorcity, setErrorcity] = useState()
  
  
  const [errorcountry, setErrorcountry] = useState(false)
  const [errorregion, setErrorregion] = useState(false)
  const [errorabout, setErrorabout] = useState(false)
  const [errorceo, setErrorceo]= useState()
 


  const [errorcontact, setErrorcontact] = useState()
  const [errorfixed, setErrorfixed] = useState()
  const [errormobile, setErrormobile] = useState()
  const [erroremail, setErroremail] = useState()

  const [success, setSuccess] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault();

    const notemptyError = {content: 'Cannot be empty', pointing: 'below'}

    let errors = false

    if(profile.logo==null){
      setErrorlogo(notemptyError)
      errors = true
    }


    if(profile.service.length===0){
      setErrorservice(notemptyError)      
      errors = true
    }

    if(profile.country==null){
      setErrorcountry(notemptyError)
      errors = true
    }    

    if(profile.region==null){
      setErrorregion(notemptyError)
      errors = true
    }        


    if(profile.city==null){
      setErrorcity(notemptyError)
      errors = true
    }            

    if(profile.history==null){
      setErrorabout(notemptyError)
      errors = true
    }                

    if(profile.contactperson==null){
      setErrorcontact(notemptyError)
      errors = true
    }                
    
    if(profile.contactemail==null){
      setErroremail(notemptyError)
      errors = true
    }                
    
    if(profile.contactfixednumber==null){
      setErrorfixed(notemptyError)
      errors = true
    }                
    
    if(profile.contactmobilenumber==null){
      setErrormobile(notemptyError)
      errors = true
    }                    

    if(errors){
      return
    }    

    setLoader(true)
    userService.updateCustomerProfile(profile).then(()=> {
      setLoader(false)
      setSuccess(true)
    })
    .catch(error => {
      // console.log(error.response)
            history.push("/error500");
    })    

  };


  const haserrors = () => errorlogo || errorabout || errorservice ||
                          errorcountry || errorregion || errorcity || 
                          errorcontact || errorfixed || errormobile || erroremail    




  useEffect(() => {
    //setFetchloader(true)
    setProfileloader(true)
    const myuser = authService.getCurrentUser();
    setUser(myuser)

    if (myuser) {
      userService.getCustomerProfile().then((response) => {
        
        const myprofile = response.data;
        dispatch({ type:FETCH_STATE, payload: response.data });

        userService.getContractorServicesOptions().then((response)=> setServices(response.data))          

        userService.getCountries().then((response)=> {
          setCountries(response.data)
          if(myprofile.country)
          userService.getRegions(myprofile.country).then((response)=> setRegions(response.data))          
        });
      })
      .catch(error => {
        // console.log(error.response)
              history.push("/error500");
      })      
      .finally(()=>{  setProfileloader(false)})
    }

  }, []);



  return   (
    <Container style={{ paddingBottom: '5em' }} text>


    {profileloader &&
      <Dimmer active inverted>
        <Loader inverted>Loading profile</Loader>
      </Dimmer>
      }

    {profile.customerid ?
      <Form 
        onSubmit={handleSubmit} 
        loading={loader || !profile.customerid}
        success={success}
        error={haserrors()}       
        >

      <SegmentOverview
        profile={profile}
        user={user}        
        dispatch={dispatch}

        services={services}
        countries={countries}
        regions={regions}
        setRegions={setRegions}

        errorname = {errorname}
        setErrorname = {setErrorname}
        
        errorlogo={errorlogo}
        setErrorlogo={setErrorlogo}

        errorservice={errorservice}
        setErrorservice={setErrorservice}
        
        errorcountry={errorcountry}
        setErrorcountry={setErrorcountry}
        errorregion={errorregion}
        setErrorregion={setErrorregion}
        errorcity={errorcity}
        setErrorcity={setErrorcity}
        errorabout={errorabout}
        setErrorabout={setErrorabout}
        errorceo={errorceo}
        setErrorceo={setErrorceo}                                
      />



      <SegmentSocial 
        profile={profile}
        dispatch={dispatch}        
      />


        
      <SegmentContact 
        profile={profile}
        dispatch={dispatch}    

        errorcontact={errorcontact}
        setErrorcontact={setErrorcontact}
        erroremail={erroremail}
        setErroremail={setErroremail}
        errorfixed={errorfixed}
        setErrorfixed={setErrorfixed}
        errormobile={errormobile}
        setErrormobile={setErrormobile}
      />




        <Message
          success
          header='Profile saved successfully.'
          content="Go to 'My Needs' to publish your needs!"
        />        

        <Message
          error
          icon='warning'
          header='Cannot save form!'
          content='Form contains errors'
        />

        <Button type='submit' disabled={haserrors()}> Save </Button>
      </Form>
      :<></>}
    
    </Container>
    ) 
  

  };

export default ProfileContractorForm;