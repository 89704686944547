import { useState } from "react";
import { Segment, Label, Form, Radio } from "semantic-ui-react";



const SegmentSportMembers = (props) => {
  console.log('RENDER SegmentSportMembers')

  const profile = props.profile
  const dispatch = props.dispatch;

  const errorathletesnumber = props.errorathletesnumber
  const setErrorathletesnumber = props.setErrorathletesnumber

  const errormembersnumber = props.errormembersnumber
  const setErrormembersnumber = props.setErrormembersnumber

  const [members, setMembers] = useState()
  const [academy, setAcademy] = useState()
  const erroracademy = props.erroracademy
  const setErroracademy = props.setErroracademy  
  const errormembers = props.errormembers
  const setErrormembers = props.setErrormembers  

  const errorestablishment = props.errorestablishment
  const setErrorestablishment = props.setErrorestablishment

  const errormemberestablishment = props.errormemberestablishment
  const setErrormemberestablishment = props.setErrormemberestablishment



  const onChangeAcademy = (event, data) => {
    setAcademy (data.value)
    setErroracademy (undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }


  const onChangeMembers = (event, data) => {
    setMembers(data.value)
    setErrormembers(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }


  const onChangeInput = (event, data) => {

    if(data.name === 'athletesnumber' ){

      if(data.value.length === 0){
        setErrorathletesnumber({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      setErrorathletesnumber(undefined)
    }


    if(data.name === 'membersnumber' ){

      if(data.value.length === 0){
        setErrormembersnumber({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      setErrormembersnumber(undefined)
    }    


    if(data.name === 'academyestablishment'){
      if(data.value.length!==4){
        setErrorestablishment({content: 'Not valid format', pointing: 'below'})
        return        
      }

      setErrorestablishment(undefined)      
    }    


    if(data.name === 'membersestablishment'){
      if(data.value.length!==4){
        setErrormemberestablishment({content: 'Not valid format', pointing: 'below'})
        return        
      }

      setErrormemberestablishment(undefined)      
    }    


    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }



  return   (
  <Segment raised>
      <Label as='a' color='violet' ribbon>
          Members/Athletes
      </Label>                

      <h5>Academy</h5>
      <Form.Group className='required' inline>
            <Form.Field
              control={Radio}
              label='Yes'
              value='yes'
              checked={academy === 'yes' || profile.academy === 'yes'}
              onChange={onChangeAcademy}
              name='academy'
              error={erroracademy}              
            />

            <Form.Field
              control={Radio}
              label='No'
              value='no'
              checked={academy === 'no'|| profile.academy === 'no'}
              onChange={onChangeAcademy}
              name='academy'
            />
      </Form.Group>

        {academy === 'yes' || profile.academy === 'yes'?
        <div>
        <Form.Input className='required'
            fluid   
            label='Number of athletes'
            labelPosition='left'
            icon='user'
            iconPosition='left'
            width={5}
            defaultValue={profile.athletesnumber}
            name='athletesnumber'
            onChange={onChangeInput} 
            error={errorathletesnumber}
        />

        <Form.Input 
          label='Year of establishment'
          defaultValue={profile.academyestablishment} 
          placeholder='YYYY'
          name='academyestablishment'
          onChange={onChangeInput} 
          error={ errorestablishment}
        />

        <Form.TextArea 
          label='Achievements'
          defaultValue={profile.academyhistory} 
          placeholder='Short description'
          name='academyhistory'
          onChange={onChangeInput} 
        />
        </div>
        :<></>
        }


        <h5>Members</h5>
          <Form.Group inline className='required'>
            <Form.Field
              control={Radio}
              label='Yes'
              value='yes'
              checked={members === 'yes' || profile.members === 'yes'}
              onChange={onChangeMembers}
              name='members'
              error={errormembers}              
            />

            <Form.Field
              control={Radio}
              label='No'
              value='no'
              checked={members === 'no' || profile.members === 'no'}
              onChange={onChangeMembers}
              name='members'
            />
        </Form.Group>

        {members === 'yes' || profile.members === 'yes'?
        <div>
            <Form.Input className='required'
                fluid   
                label='Number of members'
                labelPosition='left'
                icon='user'
                iconPosition='left'
                width={5}
                defaultValue={profile.membersnumber}
                name='membersnumber'
                onChange={onChangeInput} 
                error={errormembersnumber}
            />

            <Form.Input 
              label='Year of establishment'
              defaultValue={profile.membersestablishment} 
              placeholder='YYYY'
              name='membersestablishment'
              onChange={onChangeInput} 
              error={errormemberestablishment}
            />

            <Form.TextArea 
              label='About'
              defaultValue={profile.membershistory} 
              placeholder='Short description'
              name='membershistory'
              onChange={onChangeInput} 
            />
        </div>
        :<></>
        }          
  

   
  </Segment>


    ) 
  };

export default SegmentSportMembers;