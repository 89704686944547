import { useState, useEffect } from "react";
import { Button, Dimmer, Header, Image, Loader, Segment, Table } from "semantic-ui-react";
import ContractorOfferHeadRow from "./ContractorOfferHeadRow";
import {userService} from "../../../services/user.service";
import {authService} from "../../../services/auth.service";
import { config } from "../../../Config";
import { Link } from "react-router-dom";






const ContractorOfferDetails = (props) => {
  console.log('RENDER ContractorOfferDetails')
  // console.log(props)

  const publish = props.publish

  const pid = props.pid
  const nid = props.nid

  const [matches, setMatches] = useState()  
  const [loader, setLoader] = useState(false)
  const [pname, setPname] = useState()


  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      userService.getMyPackage(pid).then((response) =>   {
      setPname (response.data.name)
      // console.log(response.data)
      }
      )
    }
  }, [pid]);



  const onSearch = (e) => {
    setLoader(true)
    userService.getMyMatches(nid).then((response) => {
      setMatches(response.data)
      setLoader(false)
    })
  };


// console.log('mmm',matches)
return publish? (
  <div style={{marginLeft:'2em', marginRight:'2em'}}>
            <h3>My published offer for <b>{pname}</b></h3>

      <Table>
          <Table.Header>
            <ContractorOfferHeadRow />
          </Table.Header>

          <Table.Body>
          <Table.Row>
                <Table.Cell >{publish.creationDate}</Table.Cell>
                <Table.Cell>{publish.country}</Table.Cell>                            
                <Table.Cell>{publish.region==null?'All regions':publish.region}</Table.Cell>
                <Table.Cell>{publish.service}</Table.Cell>
                <Table.Cell>{publish.cost.toLocaleString('en-US')}</Table.Cell>

                <Table.Cell>{publish.from}</Table.Cell>              
                <Table.Cell>{publish.to}</Table.Cell>
            <Table.Cell style={{color:publish.active?'green':'red'}} >{publish.active?'ACTIVE':'DISABLED'}</Table.Cell>              
            <Table.Cell> 
                <Button onClick={onSearch} disabled={!publish.active}> Find Needs </Button>  
            </Table.Cell>
        </Table.Row>
          </Table.Body>
       </Table>
        

       {matches || loader ? (
            <Segment compact >
                    <h4>Found {matches && matches.length} matches</h4>
                    <Table basic='very' celled collapsing>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>                        
                        <Table.HeaderCell>Country</Table.HeaderCell>
                        <Table.HeaderCell>Region</Table.HeaderCell>                    
                        <Table.HeaderCell>From</Table.HeaderCell>          
                        <Table.HeaderCell>To</Table.HeaderCell>          
                        {/* <Table.HeaderCell>Favorite</Table.HeaderCell> */}
                        </Table.Row>
                    </Table.Header>
                
                    <Table.Body>
                        {matches && matches.length === 0 ? 
                    <Table.Row >
                        <Table.Cell>Sorry no matches found</Table.Cell>
                    </Table.Row>
                    :
                    <></>
                    }

                    {loader ?
                    <Table.Row >
                        <Table.Cell>
                            <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                            </Dimmer>              
                        </Table.Cell>
                    </Table.Row>      
                    : <></>
                    }

                        {matches &&  matches.map(m => 
                        <Table.Row>
                        <Table.Cell>
                            <Header as='h4' image>
                            
                            {m.logo &&  
                            <Image src={ `${config.url.API_BASE_URL}/customers/${m.customerid}/image/logo`} rounded size='mini' />}


                            <Header.Content>
                            <Link to={`/customers/${m.customerid}`}>{m.name}</Link>

                            </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{m.custtype}</Table.Cell>                                            
                        <Table.Cell>{m.country}</Table.Cell>                    
                        <Table.Cell>{m.region}</Table.Cell>
                        <Table.Cell>{m.from}</Table.Cell>          
                        <Table.Cell>{m.to}</Table.Cell>          
                        {/* <Table.Cell> {m.favorite? <Icon color='red' name='heart outline' /> : <Icon name='heart outline' />}  </Table.Cell> */}
                        </Table.Row>
                        )}
                        </Table.Body>
                        </Table>  
                </Segment>
    )
    :(<></>)}


    </div>
) :<></>
  
};

export default ContractorOfferDetails;