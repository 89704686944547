import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { Segment, Message, Icon, Image, Popup } from 'semantic-ui-react';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  cursor:'pointer'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};




function MyDropZone(props) {

  // const [files, setFiles] = useState([]);
  const files = props.files
  const setFiles = props.setFiles
  const setErrorphotos = props.setErrorphotos

  const [errormaxfiles, setErrormaxfiles] = useState()

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    multiple:true,
    onDrop: acceptedFiles => {
      let existfiles = [...files]

      if(existfiles.length === 10){
        setErrormaxfiles('Max number of files is reached!')
        return
      }

      let found = existfiles.find(el => el.name === acceptedFiles[0].name)
      if(found){
        return
      }

      const newfiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))

      setFiles( [ ...existfiles, ...newfiles] );
      setErrorphotos(undefined)
    }
  });
  

  const onclickdelete = (fname) => {
    console.log('delete', fname)
    let myfiles = [...files]

    let removedfiles = myfiles.filter(el => el.name !== fname)
    setFiles(removedfiles)
  }
  
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <Popup content='Click to delete' trigger={
        <Image
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
          onClick={() => onclickdelete(file.name)}
        />}
        />
        
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);


  const style = { display: 'flex', justifyContent: 'center', alignItems: 'center'}

  // console.log('files', files)
  return (

    
    <Segment style={{width:'50em'}}>

      {errormaxfiles?
        <Message icon negative>
          <Icon name='warning'  />
          <Message.Header> {errormaxfiles}</Message.Header>
      </Message>
      :<></>
      }


      <div style={{border: '2px solid grey', backgroundColor:'#B8B8B8', height:'2em', width:'10em', cursor:'pointer'}}  {...getRootProps({className: 'dropzone'})}>
      <p style={style}>Select file</p>
        <input {...getInputProps()} />

      </div>

      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </Segment>
  );
}

export default MyDropZone;