import {config} from '../../Config'
import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Modal, Header, Icon, Message, Image, Confirm } from "semantic-ui-react";
import { useHistory } from 'react-router-dom'
import {DateUtils} from "../../services/Utils";
import MyDropZone from "./MyDropZone";


const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;
    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }
    default:
      return profile;
  }  
}



const OrganisationOfferForm = (props) => {
  console.log('RENDER OrganisationOfferForm')

  const initialState = {};

  const pid = props.pid
  const nid = props.nid

  const [open, setOpen] = useState(false)
  const history = useHistory();

  const [loader, setLoader] = useState(false)

  const [offer, dispatch] = useReducer(formReducer, initialState);
  const [files, setFiles] = useState([]);

  const [currentUser, setCurrentUser] = useState(undefined);

  // const [orgoptionsspec, setOrgoptionsspec] = useState([])

  const [promotions, setPromotions] = useState([])
  const [promotiontool, setPromotiontool] = useState([])
  const [promotiontoolspec, setPromotiontoolspec] = useState([])
  const [promloader, setPromloader] = useState(false)
  const [offersaved, setOffersaved] = useState(false)
  const [promotion, setPromotion] = useState()
  const [digitalpromo, setDigitalpromo] = useState(false)
  const [status, setStatus] = useState()

  //errors
  const [errorpromotion, setErrorpromotion] = useState()
  const [errorpromotiontool, setErrorpromotiontool] = useState()
  const [errorpromotiontoolspec, setErrorpromotiontoolspec] = useState()
  const [errordescription, setErrordescription] = useState()
  const [errorsocialmedialink, setErrorsocialmedialink] = useState()
  const [errorphotos, setErrorphotos] = useState()
  const [errorcost, setErrorcost] = useState()
  const [errorfrom, setErrorfrom] = useState()
  const [errorto, setErrorto] = useState()
  const [publisherror, setPublisherror] = useState(false)






  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      setCurrentUser(user);

      if(nid){
          userService.getMyNeed(nid).then((response) => {
            const thisoffer = response.data
            dispatch({ type:FETCH_STATE, payload: thisoffer });

            //if(response.data.custtype) setOrgoptionsspec([{ key:'mykey', value:'mykey', text:response.data.custtype}])
            if(response.data.promotion) setPromotions([{  value:'mykey', text:response.data.promotion}])
            if(response.data.promotiontool) setPromotiontool([{  value:'mykey', text:response.data.promotiontool}])
            if(response.data.promotiontoolspec) setPromotiontoolspec([{  value:'mykey', text:response.data.promotiontoolspec}])

            setStatus(thisoffer.active)
          } )        
      } else {
        setPromloader(true)
        userService.getPromotions(user.customerparenttypeid, user.customertypeid).then((response) => {
          setPromloader(false)
          setPromotions(response.data)
        })
      }
    }
  }, []);



  const haserrors = () =>  errorpromotion || errorpromotiontool || errorpromotiontoolspec || 
                           errordescription || errorphotos ||
                           errorsocialmedialink ||
                           errorcost || 
                           errorfrom || errorto

  const notemptyError = {content: 'Cannot be empty', pointing: 'below'}
  const numberonly = {content: 'Numbers allowed only', pointing: 'below'}
  const notvaliddate = {content: 'Not valid date format', pointing: 'below'}
  const notvaliddateperiod = {content: 'Not valid date period', pointing: 'below'}
  const pastdate = {content: 'Date belongs to the past', pointing: 'below'}



  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = false

    if(offer.promotion==null){
      setErrorpromotion(notemptyError)
      errors = true
    }        

    if(promotiontool.length > 0 && offer.promotiontool==null){
      setErrorpromotiontool(notemptyError)
      errors = true
    }            

    if(promotiontoolspec.length > 0 && offer.promotiontoolspec==null){
      setErrorpromotiontoolspec(notemptyError)
      errors = true
    }                


    if(offer.description==null){
      setErrordescription(notemptyError)
      errors = true
    }    

    if(digitalpromo && offer.socialmedialink==null){
      setErrorsocialmedialink(notemptyError)
      errors = true
    }


    let b1 = !digitalpromo && !nid && (files == null || files.length === 0)
    let b2 = nid && files.length === 0 && offer.photos.length === 0
    let d = (currentUser.customerparenttype == 'CULTURE' || currentUser.customerparenttype == 'MEDIA' || currentUser.customerparenttype == 'SPORT' || currentUser.customerparenttype == 'TALENT') && promotion == 'adv' && files.length === 0

    
    if(b1 || b2 || d ){
      setErrorphotos('No photos have been selected')
      errors = true      
    }

    //DEFAULT    
    if(offer.cost==null){
      setErrorcost(notemptyError)
      errors = true
    }    


    if(offer.cost!=null && !DateUtils.isValidNumber( offer.cost)){
      setErrorcost(numberonly)
      errors = true
    }        


    if(offer.from==null){
      setErrorfrom(notemptyError)
      errors = true
    }        

    if(offer.from!=null && !DateUtils.dateIsValid(offer.from)){
      setErrorfrom(notvaliddate)
      errors = true
    }            
    
    if(offer.to==null){
      setErrorto(notemptyError)
      errors = true
    }            

    if(offer.to != null && !DateUtils.dateIsValid(offer.to)){
      setErrorto(notvaliddate)
      errors = true
    }                


    if(offer.to != null && offer.from != null && DateUtils.dateIsValid(offer.to) && DateUtils.dateIsValid(offer.from) && DateUtils.getTimestamp(offer.to) < DateUtils.getTimestamp(offer.from)){
      setErrorto(notvaliddateperiod)
      setErrorfrom(notvaliddateperiod)      
      errors = true
    }


    if(offer.to && DateUtils.dateIsPast  ( offer.to) ){
      setErrorto(pastdate)
      errors = true
    }           

    if(errors){
      return
    }

    setLoader(true)
    setPublisherror(false)
    setOffersaved(false)

    if(!nid){
      if(files.length > 0){
        const formdata = new FormData()
        files.forEach(f => formdata.append("myphotos", f))


        formdata.append("offer",  new Blob([JSON.stringify(offer)], {type : 'application/json'}))

        userService.createMultipartOffer(formdata, pid).then(()=> {
          setOffersaved(true)
        })
        .catch(error => {
          if(error.response.status === 409){
            setPublisherror('Offer already exists for dates specified')
          }
  
          if(error.response.status === 500){
            setPublisherror('Server responded with unknown error')
          }        
        })
        .finally(() => {
          setLoader(false)
        })        
      } else {
        userService.createOffer(offer, pid).then(()=> {
          setOffersaved(true)
        })
        .catch(error => {
          if(error.response.status === 409){
            setPublisherror('Offer already exists for dates specified')
          }
  
          if(error.response.status === 500){
            setPublisherror('Server responded with unknown error')
          }        
        })
        .finally(() => {
          setLoader(false)
        })
    }
      
      
    } else {
      if(files.length > 0){

        const formdata = new FormData()
        files.forEach(f => formdata.append("myphotos", f))


        formdata.append("offer",  new Blob([JSON.stringify(offer)], {type : 'application/json'}))

        userService.updateMultipartOffer(formdata, nid).then(()=> {
          setOffersaved(true)
          setLoader(false)
        })
        .catch(error => {
          // console.log(error)
          if(error.response && error.response.status === 409){
            setPublisherror('Offer already exists for dates specified')
            return
          }

          if(error.response && error.response.status === 500){
            setPublisherror('Server responded with unknown error')
            return
          }        

          setPublisherror('Cannot reach server')
        })
        .finally(() => {
          setLoader(false)
        })
        } else {
          userService.updateOffer(offer, nid).then(()=> {
            setOffersaved(true)
            setLoader(false)
          })
          .catch(error => {
            // console.log(error)
            if(error.response && error.response.status === 409){
              setPublisherror('Offer already exists for dates specified')
              return
            }

            if(error.response && error.response.status === 500){
              setPublisherror('Server responded with unknown error')
              return
            }        

            setPublisherror('Cannot reach server')
          })
          .finally(() => {
            setLoader(false)
          })        
        }      
    }
  };





  const onChangeInput = (event, data) => {
    setOffersaved(false)

    if(data.name === 'promotiontoolspec' && data.value != null){
      setErrorpromotiontoolspec (undefined)
    }        


    if(data.name === 'description'){
      if(data.value === ''){
        setErrordescription(notemptyError)
        return
      } 


      if(data.value.split(' ').length > 100){
        setErrordescription({content: 'Max 100 words allowed', pointing: 'below'})
        return
      } 

      setErrordescription(undefined)
    } 


    
    if(data.name === 'socialmedialink'){
      if(data.value === ''){
        setErrorsocialmedialink(notemptyError)
        return
      } 
      setErrorsocialmedialink(undefined)
    }     


    if(data.name === 'cost'){
      if(data.value === ''){
        setErrorcost(notemptyError)
        return
      } 

      if(!DateUtils.isValidNumber(data.value)){
        setErrorcost(numberonly)
        return
      }       

      setErrorcost(undefined)
    } 


    // FROM date period    
    if(data.name === 'from'){
      if(data.value === ''){
        setErrorfrom(notemptyError)
        return
      } 

      if(!DateUtils.dateIsValid( data.value) ){
        setErrorfrom(notvaliddate)
        return
      }       

      if(DateUtils.dateIsPast  ( data.value) ){
        setErrorfrom(pastdate)
        return
      }       


      if(offer.to!=null && DateUtils.dateIsValid(offer.to) && DateUtils.dateIsValid(data.value) && DateUtils.getTimestamp(offer.to) < DateUtils.getTimestamp(data.value)){
        setErrorto(notvaliddateperiod)
        setErrorfrom(notvaliddateperiod)      
        // return
      }      

      if(offer.to!=null && DateUtils.dateIsValid(offer.to) && DateUtils.dateIsValid(data.value) && DateUtils.getTimestamp(offer.to) >= DateUtils.getTimestamp(data.value)){
        setErrorto(undefined)
        setErrorfrom(undefined)      
        
      }            

      setErrorfrom(undefined)
    }    

    
    // TO date period    
    if(data.name === 'to'){
      if(data.value === ''){
        setErrorto(notemptyError)
        return
      } 

      if(!DateUtils.dateIsValid( data.value) ){
        setErrorto(notvaliddate)
        return
      }      
      

      if(DateUtils.dateIsPast  ( data.value) ){
        setErrorto(pastdate)
        return
      }       


      if(offer.from!=null && DateUtils.dateIsValid(data.value) && DateUtils.dateIsValid(offer.from) && DateUtils.getTimestamp(data.value) < DateUtils.getTimestamp(offer.from)){
        setErrorto(notvaliddateperiod)
        setErrorfrom(notvaliddateperiod)      
        // return
      }            

      if(offer.from!=null && DateUtils.dateIsValid(data.value) && DateUtils.dateIsValid(offer.from) && DateUtils.getTimestamp(data.value) >= DateUtils.getTimestamp(offer.from)){
        setErrorto(undefined)
        setErrorfrom(undefined)      
      }                  
      
      setErrorto(undefined)
    }        

    dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
  }



  const onChangePromotion = (e, data) => {
    setErrorpromotion(undefined)
    setOffersaved(false)
    setDigitalpromo(false)

    let text = e.nativeEvent.target.innerText;


    if(text === 'SPONSORSHIP')
      setPromotion('spons')

    if(text === 'ADVERTISING')
      setPromotion('adv')

    if(text === 'HOSPITALITY')
      setPromotion('hosp')

    offer.promotiontoolspec = null
    setPromotiontoolspec([])

    let promotion = promotions.filter(p => p.value === data.value)
    // console.log(promotion[0])
    setPromotiontool(promotion[0].child)

    dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
    dispatch({type:UPDATE_STATE, name: 'promotiontool', value: null});
    dispatch({type:UPDATE_STATE, name: 'promotiontoolspec', value: null});
  };


  const onChangePromotionTool = (e, data) => {
    setErrorpromotiontool(undefined)
    setOffersaved(false)

    let text = e.nativeEvent.target.innerText;
    
    setDigitalpromo(text.includes( 'DIGITAL'))
    
    let promotion = promotiontool.filter(p => p.value === data.value)
    setPromotiontoolspec(promotion[0].child)

    dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
    dispatch({type:UPDATE_STATE, name: 'promotiontoolspec', value: null});
  };


  console.log('myoffer',offer)
  // console.log('user',currentUser)
  console.log('files',files)

  const [confirm, setConfirm] = useState()
  const [delfile, setDelfile] = useState()

  const ishosp = offer.promotion === 'HOSPITALITY' || promotion === 'hosp';
  // const check = (offer.promotiontool && ( currentUser.customerparenttype === 'SPORT' || currentUser.customerparenttype === 'MEDIA' || currentUser.customerparenttype === 'TALENT' )) 
  //       || promotion === 'hosp' 
  //       || (offer.promotiontoolspec && !digitalpromo)

  return (

    <Container >
      <h4>Fill in your offer form </h4>


        {offersaved &&
          <Message icon success>
             <Icon name='warning'  />
             <Message.Content>
            <Message.Header>
              Your offer has been saved successfully!
            </Message.Header>
            <p>
              Navigate to <b>My Packages</b> to see your offers
            </p>
            </Message.Content>
          </Message>
        }                    

      <Form 
        onSubmit={handleSubmit} 
        loading={loader || promloader} 
        error={haserrors() || publisherror}
        >


      { promotions.length > 0 &&
        <Form.Select 
          width={5}
          placeholder='Select Cooperation model' 
          required 
          name='promotion'
          label='Cooperation model' 
          defaultValue={'mykey'} 
          options={promotions}  
          onChange={onChangePromotion} 
          error={errorpromotion}
        />
        }


        { promotiontool.length > 0 &&
          <Form.Select 
            placeholder='Select Category' 
            required 
            width={5}
            name='promotiontool'
            label='Category' 
            defaultValue={'mykey'} 
            options={promotiontool}  
            onChange={onChangePromotionTool} 
            error={errorpromotiontool}
          />
        }


        { promotiontoolspec.length > 0 &&
          <Form.Select 
            placeholder='Select Promotion tool spec' 
            required 
            width={5}
            name='promotiontoolspec'
            label='Promotion tool spec' 
            defaultValue={'mykey'} 
            options={promotiontoolspec}  
            onChange={onChangeInput} 
            error={errorpromotiontoolspec}
          />          
        }        


        {(ishosp || (offer.promotiontool && (currentUser.customerparenttype === 'MEDIA'|| currentUser.customerparenttype === 'TALENT' || currentUser.customerparenttype === 'SPORT')) || promotion === 'hosp' || offer.promotiontoolspec) ?
          <Form.TextArea className='required'
            label='Description'
            defaultValue={offer.description} 
            width={10}
            placeholder='Location, dimensions etc'
            name='description'
            error={errordescription}
            onChange={onChangeInput} 
          />:<></>
        }


        {(digitalpromo || offer.socialmedialink) &&
          <Form.Input  className='required'
            width={5} 
            label='Social media URL'
            defaultValue={offer.socialmedialink} 
            placeholder='Social media URL' 
            name='socialmedialink'
            onChange={onChangeInput} 
            error={errorsocialmedialink}
          />          
        }

        
    {
     (offer.promotiontool && 
      ( currentUser.customerparenttype === 'CULTURE' ||
        currentUser.customerparenttype === 'SPORT' || 
        currentUser.customerparenttype === 'MEDIA' || 
        currentUser.customerparenttype === 'TALENT' )) || 
        ishosp || 
        offer.promotiontoolspec && 
        !digitalpromo && 
        offer.promotiontool !== "DIGITAL AND SOCIAL MEDIA PROMO" ?
        <div>
          <div>
              <h5 className={'myrequired'}>Photos</h5>
              {errorphotos &&
                <Message icon negative>
                  <Icon name='warning'  />
                  <Message.Header> {errorphotos}</Message.Header>
                </Message>
              }            

              <MyDropZone 
                files={files}
                setFiles={setFiles}
                setErrorphotos={setErrorphotos}
              />

                <Confirm 
                  open={confirm} 
                  onCancel={() => setConfirm(false)}
                  onConfirm={() => {
                    setConfirm(false)
                    const newphotos = offer.photos.filter(ph => ph !== delfile)
                    dispatch({type:UPDATE_STATE, name: 'photos', value: newphotos});
                  }}
                  content={`Remove file: ${delfile}?`}
                />              


            {offer && offer.photos && offer.photos.length > 0 ?
            <Image.Group width={2}>
              {offer.photos.map(f =>
              
                <>
                <Image 
                  src={ `${config.url.API_BASE_URL}/customers/${currentUser.customerid}/image/offer?filename=${f}`} 
                  size='small' 
                  style={{cursor:'pointer'}}
                  onClick={() => {
                    
                    setConfirm(true)
                    setDelfile(f)
                  }
                  }
                />  
              </>
              )}

            </Image.Group>   
            :<></>     
            }


          </div>
          <div style={{marginTop:'0.5em'}} >&nbsp;</div>          
        </div>
        :<></>
        }          



          <Form.Input  className='required'
            width={5} 
            label='Cost'
            defaultValue={offer.cost} 
            placeholder='Cost' 
            name='cost'
            onChange={onChangeInput} 
            error={errorcost}
          />


          <Form.Input className='required'
            width={5} 
            label='Period from'
            defaultValue={offer.from} 
            placeholder='DD-MM-YYYY' 
            name='from'
            onChange={onChangeInput} 
            error={errorfrom}
          />

          <Form.Input className='required'
            width={5} 
            label='Period to'
            defaultValue={offer.to} 
            placeholder='DD-MM-YYYY' 
            name='to'
            onChange={onChangeInput} 
            error={errorto}
          />


          {status !== undefined &&
            <Form.Group inline>
            <label className='required'>{status?'Active':'Disabled'}</label>
              <Form.Radio 
                toggle
                value={'on'}
                color={'green'}
                checked={status}
                name='active'
                onChange={()=> {

                  if(!status && DateUtils.dateIsPast(offer.to) ){
                    setErrorto(pastdate)
                    return
                  }                             
                  setStatus(!status)

                  dispatch({type:UPDATE_STATE, name: 'active', value: !offer.active});
                }}
              />
              </Form.Group>
          }          



          <Message
           error
           icon='warning'
           header='Cannot publish offer!'
           content={publisherror ? publisherror:'Form contains errors'}
          />

          <Button type='submit' disabled={haserrors()}> Submit </Button>

          {nid &&
              <Modal 
                  closeIcon
                  open={open}
                  trigger={<Button type='button' style={{marginLeft:'13em'}} color='red'>Delete</Button>}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}>
                
                <Header icon='warning' content='Delete offer' />

                <Modal.Content>
                  <p> Are you sure you want to delete this offer ? </p>
                </Modal.Content>
                
                <Modal.Actions>
                  <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> No
                  </Button>

                  <Button color='green' onClick={() => {
                      setLoader(true)
                      userService.deleteMyOffer(pid, nid).then(()=> {

                        history.goBack();
                      })

                      setOpen(false)
                    }}>
                    <Icon name='checkmark' /> Yes
                  </Button>
                </Modal.Actions>
              </Modal>        
            }                              
      </Form>
    </Container>    
    )
};

export default OrganisationOfferForm;