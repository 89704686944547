import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Message, Segment, Label, Icon, Dimmer, Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import SegmentOverview from "./modules/SegmentOverview";
import SegmentSportMembers from "./modules/SegmentSportMembers";
import SegmentFacility from "./modules/SegmentFacility";
import ProductCompetition from "./modules/ProductCompetition";
import SegmentAudience from "./modules/SegmentAudience";
import SegmentSocial from "./modules/SegmentSocial";
import SegmentContact from "./modules/SegmentContact";
import {Messages} from "../../services/Messages";




const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;

    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }

    case 'UPDATE_COMPETITION_SCHEDULE': {
      // debugger
        const myschedule = event.schedule
        const compid = event.id
        let mycompetitions = profile.mycompetitions

        let objIndex = mycompetitions.findIndex((obj => obj.id === compid));
        mycompetitions[objIndex].myevents=myschedule
        return {...profile, mycompetitions}
    }

    case 'UPDATE_COMPETITION_STATE': {
      // debugger
      const mycompetitionid = event.id
      let name = event.name
      let value = event.value
      let mycompetitions;
      if(!profile.mycompetitions){
        mycompetitions = []

        mycompetitions.push({id:mycompetitionid ,[name]:value})
      } else{
        mycompetitions = profile.mycompetitions
        let objIndex = mycompetitions.findIndex((obj => obj.id === mycompetitionid));
        if(objIndex===-1)
          mycompetitions.push({id:mycompetitionid ,[name]:value})
        else
          mycompetitions[objIndex][name]=value
      }

      return {...profile, mycompetitions}
  }    
    
    case 'REMOVE_COMPETITION_SCHEDULE': {
      const removeid = event.id

      if(profile.mycompetitions){
      let mycompetitions = profile.mycompetitions.filter(el => el.id !== removeid)
      return {...profile, mycompetitions}
      }

    } 
  
    case 'UPDATE_OTHER_PRODUCT': {
      const myotherevents = event.target
      return {...profile, myotherevents}
    }   

    default:
      return profile;
  }  
 }


 const initialState = {};


const ProfileOrgSportForm = () => {
  console.log('RENDER ProfileOrgSportForm')

  // const [fetchloader, setFetchloader] = useState(false)
  const [user, setUser] = useState()
    
  const [profile, dispatch] = useReducer(formReducer, initialState);
  const [profileloader, setProfileloader] = useState(false)
  const [errorlogo, setErrorlogo] = useState()
  const [errorcountry, setErrorcountry] = useState(false)
  const [errorregion, setErrorregion] = useState(false)
  const [errorabout, setErrorabout] = useState(false)

  const [loader, setLoader] = useState(false)

  const history = useHistory();
  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])
  
  const [competitiontypes, setCompetitiontypes] = useState([])
  const [mycompetitions, setMycompetitions] = useState([])



  const [errorname, setErrorname] = useState()
  const [errorcity, setErrorcity] = useState()
  const [errorceo, setErrorceo] = useState()
  const [errorestablishment, setErrorestablishment] = useState()
  const [erroracademyestablishment, setErroracademyestablishment] = useState()
  const [errormemberestablishment, setErrormemberestablishment] = useState()

  const [errorfacilitycapacity, setErrorfacilitycapacity] = useState()
  const [errorfacilityphoto, setErrorfacilityphoto] = useState()

  const [erroracademy, setErroracademy] = useState()
  const [errorathletesnumber, setErrorathletesnumber] = useState()
  
  const [errormembers, setErrormembers] = useState()
  const [errormembersnumber, setErrormembersnumber] = useState()
  const [erroravgticketholders, setErroravgticketholders]=useState()
  const [erroravgticketholderschamp, setErroravgticketholderschamp]=useState()
  const [erroravgticketholderscup, setErroravgticketholderscup]=useState()
  const [erroravgticketholdersother, setErroravgticketholdersother]=useState()
  const [errorseasonticketholders, setErrorseasonticketholders] = useState()
  const [errortvbroadcastingnumber, setErrortvbroadcastingnumber] = useState()  

  const [errorcontact, setErrorcontact] = useState()
  const [errorfixed, setErrorfixed] = useState()
  const [errormobile, setErrormobile] = useState()
  const [erroremail, setErroremail] = useState()
  const [errorfacilityname, setErrorfacilityname] = useState()

  const [errortvbroadcasting, setErrortvbroadcasting] = useState()
  const [errorradiobroadcasting, setErrorradiobroadcasting] = useState()
  const [errornewscoverage, setErrornewscoverage] = useState()
  const [errormagazinescoverage, setErrormagazinescoverage] = useState()
  const [errorwebcoverage, setErrorwebcoverage] = useState()

  const [errormycompetitions, setErrormycompetitions] = useState()

  const [success, setSuccess] = useState(false)



  const handleSubmit = (e) => {
    e.preventDefault();



    let errors = false

    //**** OVERVIEW ******
    if(profile.logo==null){
      setErrorlogo(Messages.notemptyError)
      errors = true
    }

    if(profile.country==null){
      setErrorcountry(Messages.notemptyError)
      errors = true
    }    

    if(profile.region==null){
      setErrorregion(Messages.notemptyError)
      errors = true
    }        


    if(profile.city==null){
      setErrorcity(Messages.notemptyError)
      errors = true
    }            

    if(profile.establishmentdate == null){
        setErrorestablishment(Messages.notValidFormat)
        errors = true
    }    

    if(profile.academyestablishment != null && profile.academyestablishment.length!==4){
      setErroracademyestablishment(Messages.notValidFormat)
      errors = true
    }        

    if(profile.memberestablishment != null && profile.memberestablishment.length!==4){
      setErrormemberestablishment(Messages.notValidFormat)
      errors = true
    }        

    if(profile.history==null){
      setErrorabout(Messages.notemptyError)
      errors = true
    }                

    console.log('form errors=>', errors)


    //******* FACILITY */
    if(profile.facilityname==null){
          setErrorfacilityname(Messages.notemptyError)
          errors = true
     }                
    
    
    if(profile.facilitycapacity==null){
          setErrorfacilitycapacity(Messages.notemptyError)
          errors = true
        }                
    
        
    if(profile.facilityphoto==null){
          setErrorfacilityphoto(Messages.notemptyError)
          errors = true
        }


    //******** ACADEMY */
    if(profile.academy==null){
      setErroracademy(Messages.notemptyError)
      errors = true
    }

    if(profile.academy==='yes' && profile.athletesnumber==null){
      setErrorathletesnumber(Messages.notemptyError)
      errors = true
    }    
    
    if(profile.members==null){
      setErrormembers(Messages.notemptyError)
      errors = true
    }            


    if(profile.members==='yes' && profile.membersnumber==null){
      setErrormembersnumber(Messages.notemptyError)
      errors = true
    }            


    //******** AUDIENCE */
    if(profile.avgticketholders==null){
      setErroravgticketholders(Messages.notemptyError)
      errors = true
    }

    if(profile.avgticketholderschamp==null){
      setErroravgticketholderschamp(Messages.notemptyError)
      errors = true
    }

    if(profile.avgticketholderscup==null){
      setErroravgticketholderscup(Messages.notemptyError)
      errors = true
    }

    if(profile.avgticketholdersother==null){
      setErroravgticketholdersother(Messages.notemptyError)
      errors = true
    }

    if(profile.seasonticketholders==null){
      setErrorseasonticketholders(Messages.notemptyError)
      errors = true
    }    
    
    if(profile.tvbroadcasting==null){
      setErrortvbroadcasting(Messages.notemptyError)
      errors = true
    }    

    if(profile.radiobroadcasting==null){
      setErrorradiobroadcasting(Messages.notemptyError)
      errors = true
    }    

    if(profile.newspaperscoverage==null){
      setErrornewscoverage(Messages.notemptyError)
      errors = true
    }    


    if(profile.magazinescoverage==null){
      setErrormagazinescoverage(Messages.notemptyError)
      errors = true
    }
    
    if(profile.webcoverage==null){
      setErrorwebcoverage(Messages.notemptyError)
      errors = true
    }    


    //******** PRODUCT */
    if(profile.mycompetitions==null || profile.mycompetitions.length ===0){
      setErrormycompetitions(Messages.notemptyError)
      errors = true
    }     
    
    
    if(profile.mycompetitions != null){
      profile.mycompetitions.forEach((v,i,array)=>{
        if(v.schedule === 'online' && v.scheduleurl == null){
          setErrormycompetitions(Messages.dataIsMissingError)
          errors = true
        }

        if(v.schedule === 'manual' && (v.myevents == null || v.myevents.length === 0)){
          setErrormycompetitions(Messages.dataIsMissingError)
          errors = true
        }        
      })
    }





    //******** CONTACT */
    if(profile.contactperson==null){
      setErrorcontact(Messages.notemptyError)
      errors = true
    }                
    
    if(profile.contactemail==null){
      setErroremail(Messages.notemptyError)
      errors = true
    }                
    
    if(profile.contactfixednumber==null){
      setErrorfixed(Messages.notemptyError)
      errors = true
    }                
    
    if(profile.contactmobilenumber==null){
      setErrormobile(Messages.notemptyError)
      errors = true
    }                    


    console.log('form errors=>', errors)
    if(errors){
      return
    }        


    setLoader(true)
    userService.updateCustomerProfile(profile).then(()=> {
      setLoader(false)
      setSuccess(true)
    })
    .catch(error => {
      // console.log(error.response)
            history.push("/error500");
    })    

  };

  const addCompetition = () => {
    let mymax = mycompetitions.map(my => my.id)
    const maxxx = Math.max(...mymax);

    let newfield = { id:maxxx+1, name: ''}
    setMycompetitions([...mycompetitions, newfield])   
    setErrormycompetitions(undefined) 
  }


  const removeCompetition = (id) => {
    setMycompetitions(prev => prev.filter((c) => c.id !== id))  
    dispatch({type:"REMOVE_COMPETITION_SCHEDULE", id:id});
  }



  // const onChangeInput = (event, data) => {
  //   console.log(data.value.length)

  //   let valid = true

  //   // let regex = new RegExp("^[a-zA-Z0-9\r?\n$@$!%*?&#(),'^-_. +]+$");
  //   let regex = new RegExp("^[\-\={}\\[\\]`~;:\\\\|<>/\"a-zA-Z0-9\r?\n$@$!%*?&#(),'^-_. +]+$");
                

  //   dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
  // }

  const haserrors = () => errorname || errorlogo || errorabout || errorcountry || errorregion || errorceo ||errorcity || errorestablishment ||
                          errorfacilityname || errorfacilitycapacity || errorfacilityphoto ||
                          erroracademy || errorathletesnumber || errormembers || errormembersnumber || erroracademyestablishment || errormemberestablishment ||
                          erroravgticketholders || erroravgticketholderschamp || erroravgticketholderscup || erroravgticketholdersother || errorseasonticketholders ||
                          errortvbroadcasting || errorradiobroadcasting || errornewscoverage || errormagazinescoverage || errorwebcoverage ||
                          errormycompetitions ||
                          errorcontact || errorfixed || errormobile || erroremail    



  useEffect(() => {
    // setFetchloader(true)
    setProfileloader(true)
    const user = authService.getCurrentUser();
    setUser(user)
  
    if (user) {
      userService.getCustomerProfile().then((response) => {

        const myprofile = response.data;

        let arr = myprofile.mycompetitions
        if(arr && arr.length > 0){
          arr.forEach( (element,index) => {
            if(element.myevents && element.myevents.length>0){
              let x = element.myevents.map((ev,index) => { return {'name':ev} })   
              element.myevents = [...x]
            }
          });
          setMycompetitions(myprofile.mycompetitions)
        }

        userService.getCompetitionTypes().then((response)=> setCompetitiontypes(response.data))  

        dispatch({ type:FETCH_STATE, payload: response.data });

        userService.getCountries().then((response)=> {
          setCountries(response.data)
          if(myprofile.country)
          userService.getRegions(myprofile.country).then((response)=> setRegions(response.data))          
        });
        // setFetchloader(false)      
      })
      .catch(error => {
        // console.log(error.response)
              history.push("/error500");
      })      
      .finally(()=>{  setProfileloader(false)})
    }
  }, []);


  
console.log('mycompetitions',mycompetitions)
console.log('profile',profile)
  return   (
    <Container style={{ paddingBottom: '5em' }} text>

      {profileloader &&
        <Dimmer active inverted>
          <Loader inverted>Loading profile</Loader>
        </Dimmer>
        }

        {profile.customerid &&
      <Form 
        onSubmit={handleSubmit} 
        loading={loader || !profile.customerid}
        success={success}
        error={haserrors()}        
        >

      <SegmentOverview
        profile={profile}
        user={user}        
        countries={countries}
        regions={regions}
        setRegions={setRegions}
        dispatch={dispatch}

        errorname={errorname}
        setErrorname={setErrorname}        
        errorlogo={errorlogo}
        setErrorlogo={setErrorlogo}
        errorcountry={errorcountry}
        setErrorcountry={setErrorcountry}
        errorregion={errorregion}
        setErrorregion={setErrorregion}
        errorcity={errorcity}
        setErrorcity={setErrorcity}
        errorceo={errorceo}
        setErrorceo={setErrorceo}
        errorestablishment={errorestablishment}
        setErrorestablishment={setErrorestablishment}
        errorabout={errorabout}
        setErrorabout={setErrorabout}         
      />




      <SegmentFacility
        profile={profile}
        user={user}        
        dispatch={dispatch}

        errorfacilityname={errorfacilityname}
        setErrorfacilityname={setErrorfacilityname}
        errorfacilitycapacity={errorfacilitycapacity}
        setErrorfacilitycapacity={setErrorfacilitycapacity}  
        errorfacilityphoto={errorfacilityphoto}
        setErrorfacilityphoto={setErrorfacilityphoto}
      />

      <SegmentSportMembers 
        profile={profile}
        user={user}        
        dispatch={dispatch}
        
        erroracademy={erroracademy}
        setErroracademy={setErroracademy}
        errormembers={errormembers}
        setErrormembers={setErrormembers}
        errormembersnumber={errormembersnumber}
        setErrormembersnumber={setErrormembersnumber}
        errorathletesnumber={errorathletesnumber}
        setErrorathletesnumber={setErrorathletesnumber}
        errorestablishment={erroracademyestablishment}
        setErrorestablishment={setErroracademyestablishment}
        errormemberestablishment={errormemberestablishment}
        setErrormemberestablishment={setErrormemberestablishment}                
      />

        
      <SegmentAudience 
        profile={profile}
        user={user}        
        dispatch={dispatch}

        errormembersnumber={errormembersnumber}
        setErrormembersnumber={setErrormembersnumber}        

        erroravgticketholders={erroravgticketholders}
        setErroravgticketholders={setErroravgticketholders}

        erroravgticketholderschamp={erroravgticketholderschamp}
        setErroravgticketholderschamp={setErroravgticketholderschamp}
        
        erroravgticketholderscup={erroravgticketholderscup}
        setErroravgticketholderscup={setErroravgticketholderscup}
        
        erroravgticketholdersother={erroravgticketholdersother}
        setErroravgticketholdersother={setErroravgticketholdersother}
        
        errorseasonticketholders={errorseasonticketholders}
        setErrorseasonticketholders={setErrorseasonticketholders}

        errortvbroadcasting={errortvbroadcasting}
        setErrortvbroadcasting={setErrortvbroadcasting}
        errortvbroadcastingnumber={errortvbroadcastingnumber}
        setErrortvbroadcastingnumber={setErrortvbroadcastingnumber}
        errorradiobroadcasting={errorradiobroadcasting}
        setErrorradiobroadcasting={setErrorradiobroadcasting}
        errornewscoverage={errornewscoverage}
        setErrornewscoverage={setErrornewscoverage}
        errormagazinescoverage={errormagazinescoverage}
        setErrormagazinescoverage={setErrormagazinescoverage}
        errorwebcoverage={errorwebcoverage}
        setErrorwebcoverage={setErrorwebcoverage}
      />



      <Segment raised>
        <Label as='a' color='brown' ribbon>
            Product
          </Label>                

          {errormycompetitions &&
            <div style={{marginTop:'1em'}}>
              <Message
                color='red'
                icon='warning'
                content='No competions found!'
              />            
          </div>
          }

        {mycompetitions.map((c, index) =>         
          <Segment color='orange' key={c.id} >
              <ProductCompetition 
                  profile={profile}
                  user={user}        
                  dispatch={dispatch}     
                  
                  competitiontypes={competitiontypes}
                  mycompetitions={mycompetitions}
                  setMycompetitions={setMycompetitions}
                  compel={c}

                  setErrormycompetitions={setErrormycompetitions}
              />

              <div style={{marginTop:'1em'}}>
                <Button type='button' basic  onClick={() => removeCompetition(c.id)} icon > Remove Competition<Icon name='remove' color={'red'} /></Button>          
              </div>
          </Segment>
        )}

        <div style={{marginTop:'1em'}}>
          <Button type='button' onClick={addCompetition}> Add Competition</Button>
        </div>

      </Segment>      



      <SegmentSocial 
          profile={profile}
          dispatch={dispatch}        
        />


      <SegmentContact 
        profile={profile}
        dispatch={dispatch}    

        errorcontact={errorcontact}
        setErrorcontact={setErrorcontact}
        erroremail={erroremail}
        setErroremail={setErroremail}
        errorfixed={errorfixed}
        setErrorfixed={setErrorfixed}
        errormobile={errormobile}
        setErrormobile={setErrormobile}
      />

        <Message
          success
          header='Profile saved successfully.'
          content="Go to 'My Needs' to publish your needs!"
        />        

        <Message
           error
           icon='warning'
           header='Cannot save form!'
           content='Form contains errors'
        />


        <Button type='submit' disabled={haserrors()}> Save </Button>

      </Form>
      }
    
    </Container>
    ) 
  

  };

export default ProfileOrgSportForm;