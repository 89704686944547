
import { Segment, Label, Form } from "semantic-ui-react";
import {DateUtils} from "../../../services/Utils";



const SegmentContact = (props) => {
  console.log('RENDER SegmentContact')

  const profile = props.profile
  const dispatch = props.dispatch;

  const errorcontact = props.errorcontact
  const setErrorcontact = props.setErrorcontact
  const erroremail = props.erroremail
  const setErroremail = props.setErroremail
  const errorfixed = props.errorfixed
  const setErrorfixed = props.setErrorfixed
  const errormobile = props.errormobile
  const setErrormobile = props.setErrormobile

  const onChangeInput = (event, data) => {

    let regex = new RegExp("^[-={}\\[\\]`~;:\\\\|<>/\"a-zA-Z0-9?$@$!%*?&#(),'^-_. +]+$");

    if(data.name === 'contactperson' ){
      if(data.value.length > 40){
        setErrorcontact({content: 'Contact is more than 100 characters long', pointing: 'below'})
        return
      } 

      if(data.value.length === 0){
        setErrorcontact({content: 'Contact cannot be empty', pointing: 'below'})
        return
      }

      if(data.value.length > 0 && !regex.test(data.value)){ 
        setErrorcontact({content: 'English only', pointing: 'below'})
        return
      }      


      setErrorcontact(undefined)
    }
    
    if(data.name === 'contactemail' ){
      if(data.value.length > 40){
        setErroremail({content: 'Email is more than 100 characters long', pointing: 'below'})
        return
      } 

      if(data.value.length === 0){
        setErroremail({content: 'Email cannot be empty', pointing: 'below'})
        return
      }

      if(data.value.length > 0 && !regex.test(data.value)){ 
        setErroremail({content: 'English only', pointing: 'below'})
        return
      }      

      setErroremail(undefined)
    }
    
    if(data.name === 'contactfixednumber' ){
      if(data.value.length > 40){
        setErrorfixed({content: 'Website is more than 100 characters long', pointing: 'below'})
        return
      } 

      if(data.value.length === 0){
        setErrorfixed({content: 'Number cannot be empty', pointing: 'below'})
        return
      }


      if(!DateUtils.isValidNumber(  data.value)){
        setErrorfixed({content: 'Only digits are allowed', pointing: 'below'})
        return
      }      

      setErrorfixed(undefined)
    }
    
    if(data.name === 'contactmobilenumber' ){
      if(data.value.length > 40){
        setErrormobile({content: 'Website is more than 100 characters long', pointing: 'below'})
        return
      } 

      if(!DateUtils.isValidNumber(  data.value)){
        setErrormobile({content: 'Only digits are allowed', pointing: 'below'})
        return
      }       

      if(data.value.length === 0){
        setErrormobile({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      setErrormobile(undefined)
    }   
    
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }



  return   (
    <Segment raised>
      <Label as='a' color='blue' ribbon>
        Contact Details
      </Label>                  

    <div>
          <Form.Input className='required'
            label='Contact Person Name'
            fluid icon='user' 
            iconPosition='left' 
            placeholder='contact person' 
            defaultValue={profile.contactperson}
            name='contactperson'
            error={errorcontact}
            onChange={onChangeInput} 
          />                    

          <Form.Input className='required'
            label='Email'
            fluid icon='mail'
            iconPosition='left' 
            placeholder='contact email' 
            defaultValue={profile.contactemail}
            name='contactemail'
            error={erroremail}
            onChange={onChangeInput} 
          />                    

          <Form.Input className='required'
            label='Telephone Number'
            fluid icon='phone' 
            iconPosition='left' 
            placeholder='contact telephone number' 
            defaultValue={profile.contactfixednumber}
            name='contactfixednumber'
            error={errorfixed}
            onChange={onChangeInput} 
          />                    

          <Form.Input className='required'
            label='Mobile Number'
            fluid icon='mobile alternate' 
            iconPosition='left' 
            placeholder='contact mobile number' 
            defaultValue={profile.contactmobilenumber}
            name='contactmobilenumber'
            error={errormobile}
            onChange={onChangeInput} 
          />                    
      </div>

  </Segment>

    ) 
  };

export default SegmentContact;