import { Table } from "semantic-ui-react";

const ContractorNeedHeadRow = (props) => {
    console.log('RENDER ContractorNeedHeadRow')

    return (
        <Table.Row>
            <Table.HeaderCell>Created on</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Region</Table.HeaderCell>              
            <Table.HeaderCell>Service</Table.HeaderCell>
            <Table.HeaderCell>Min Budget</Table.HeaderCell>                                          
            <Table.HeaderCell>Max Budget</Table.HeaderCell>
            <Table.HeaderCell>Period From</Table.HeaderCell>
            <Table.HeaderCell>Period To</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
      </Table.Row>        
    )
}


export default ContractorNeedHeadRow;