

const notemptyError = {content: 'Cannot be empty', pointing: 'below'}

const dataIsMissingError = {content: 'Data is missing', pointing: 'below'}

const notValidFormat = {content: 'Not valid format', pointing: 'below'}


export const Messages = {
  notemptyError,
  dataIsMissingError,
  notValidFormat
}