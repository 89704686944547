
import { useState, useEffect } from "react";
import { Segment, Label, Form, Button, Icon, Message } from "semantic-ui-react";


const SegmentEgameProduct = (props) => {
  console.log('RENDER SegmentEgameProduct')

  const profile = props.profile
  const dispatch = props.dispatch;


  const [myevents, setMyevents] = useState([])

  const erroregame = props.erroregame
  const setErroregame = props.setErroregame
  const errortournamentsnumber = props.errortournamentsnumber
  const setErrortournamentsnumber = props.setErrortournamentsnumber
  const errorschedulemanual = props.errorschedulemanual
  const setErrorschedulemanual = props.setErrorschedulemanual  




  const onChangeInput = (event, data) => {  
    
    if(data.name === 'egame' ){      
      if(data.value.length===0){
        setErroregame({content: 'Cannot be empty', pointing: 'below'})
        return
      }      

      setErroregame(undefined)
    }        


    if(data.name === 'tournamentsnumber' ){      
      if(data.value.length===0){
        setErrortournamentsnumber({content: 'Cannot be empty', pointing: 'below'})
        return
      }      

      setErrortournamentsnumber(undefined)
    }            


    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }


  useEffect(() => {
    if(profile.myevents && myevents.length === 0){
      let x = profile.myevents.map((ev,index) => { return {'name':ev} })
      profile.myevents = x;
      setMyevents(profile.myevents)      
    // setMyevents(profile.myevents)
    }
  }, [profile.myevents])

  console.log('profile',profile)
  console.log('myevents',myevents)


  const updateEvent = (index, e) => {
    let data = [...myevents];
    data[index][e.target.name] = e.target.value;
    setMyevents(data);    
    setErrorschedulemanual(undefined)
    dispatch({type:"UPDATE_PRODUCT", target: data});
  }
  
  const addEvent = () => {
    let newfield = { name: ''}
    setMyevents([...myevents, newfield])    
  }
  
  const removeEvent = (index) => {
    let data = [...myevents];
    data.splice(index, 1)
    setMyevents(data)  
    dispatch({type:"UPDATE_PRODUCT", target: data});
  }
      

  return   (
    <Segment raised>
      <Label as='a' color='brown' ribbon>
          Product
      </Label>                

          <Form.Input className='required'
            fluid   
            label='E-game name'
            placeholder='E-game name' 
            width={15}
            value={profile.egame}
            name='egame'
            error={erroregame}
            onChange={onChangeInput} />


          <Form.Input className='required'
            fluid   
            label='Number of tournaments'
            placeholder='Number of tournaments' 
            width={15}
            value={profile.tournamentsnumber}
            name='tournamentsnumber'
            error={errortournamentsnumber}
            onChange={onChangeInput} />                        
    

          <div>

            {errorschedulemanual?
              <Message
                error
                icon='warning'
                content='Events are empty!'
              />            
              :<></>
            }

            <Button type='button' onClick={addEvent}> Add Event Name & Description</Button>

            {myevents && myevents.map((el, index) => (
              <Form.Group key={index} style={{marginTop:'1em'}} >

                    <input
                      name='name'
                      required
                      placeholder='Event name'
                      value={el.name}
                      onChange={e => updateEvent(index, e)}
                    />
                  <Button type='button' basic  onClick={() => removeEvent(index)} icon > <Icon name='remove' color={'red'} /></Button>
              </Form.Group>
                
                ))}
          </div>
  </Segment>      



    ) 
  };

export default SegmentEgameProduct;