import {authService} from "../../../services/auth.service";
import {userService} from "../../../services/user.service";
import  { useState, useEffect } from "react";
import { Container,  Dimmer, Loader } from "semantic-ui-react";

import {  useParams, useHistory, useLocation } from 'react-router-dom'
import ContractorNeedsTable from "./ContractorNeedsTable";
import OrgNeedsTable from "./OrgNeedsTable";
import ContractorOfferTable from "./ContractorOfferTable";
import OrgOfferTable from "./OrgOfferTable";



const PublishesByPackage = () => {
  console.log('RENDER PublishesByPackage')

  const [myneeds, setMyneeds] = useState()
  const {id} = useParams()
  const [loading, setLoading] = useState(true)
  

  const history = useHistory()

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      userService.getMyPackageNeeds(id).then((response) => { 
        setMyneeds(response.data); 
        setLoading(false)
      })
      .catch(error => {
        // console.log(error.response)
        if(error.response.status === 401){
          authService.logout();
          history.push("/login");
          window.location.reload();
        } else {
              history.push("/error500");
        }
      })      
    }
  }, []);



  const search = useLocation().search;
  const src = new URLSearchParams(search).get('src');
  const dst = new URLSearchParams(search).get('dst');
  const type = new URLSearchParams(search).get('type');

  // console.log('myneeds', myneeds)
  if(loading){
    return (
      <Container>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>  
    </Container>        
    )    
  }




  if(myneeds && src==='COMPANY' && dst==='CONTRACTOR')
  return <ContractorNeedsTable myneeds={myneeds}  id={id} packagename={myneeds.packagename} packageexpired={myneeds.packageexpired} />

  if(myneeds && src==='COMPANY' && (dst==='ORGANIZATION' || dst==='TALENT'))
  return <OrgNeedsTable myneeds={myneeds.publish} packagename={myneeds.packagename} id={id} packageexpired={myneeds.packageexpired} />  

  if(myneeds && src==='CONTRACTOR' && dst==='COMPANY' )
  return <ContractorOfferTable offers={myneeds} id={id} packageexpired={myneeds.packageexpired} /> 
  
  if(myneeds && (src==='TALENT' || src==='CULTURE' || src==='MEDIA' || src==='SPORT') && dst==='COMPANY')
  return <OrgOfferTable offers={myneeds.publish} packagename={myneeds.packagename} id={id} packageexpired={myneeds.packageexpired} /> 
  
  if(myneeds && (src==='TALENT' || src==='CULTURE' || src==='MEDIA' || src==='SPORT') && dst==='CONTRACTOR'){
    if(type==='need')
    return <ContractorNeedsTable myneeds={myneeds.publish.filter(p => p.cost === 0)} packagename={myneeds.packagename} id={id} packageexpired={myneeds.packageexpired} />   

    if(type==='offer')
    return <OrgOfferTable offers={myneeds.publish.filter(p => p.cost > 0)} packagename={myneeds.packagename} id={id} packageexpired={myneeds.packageexpired} />
  }


  if(myneeds && src==='CONTRACTOR' && ( dst==='TALENT' || dst==='ORGANIZATION' )){
    if(type==='need')
    return <OrgNeedsTable myneeds={myneeds.publish.filter(p => p.cost === 0)} packagename={myneeds.packagename} id={id} packageexpired={myneeds.packageexpired} />   

    if(type==='offer')
    return <ContractorOfferTable offers={myneeds.publish.filter(p => p.cost > 0)} packagename={myneeds.packagename} id={id} packageexpired={myneeds.packageexpired} />
  }

   

};

export default PublishesByPackage;