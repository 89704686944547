import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Message, Segment, Label, Icon, Dimmer, Loader} from "semantic-ui-react";
import ProfileSocialMediaForm from "./modules/SocialMediaForm";
import ProductCompetition from "./modules/ProductCompetition";
import SegmentOverview from "./modules/SegmentOverview";
import SegmentContact from "./modules/SegmentContact";
import SegmentEgameProduct from "./modules/SegmentEgameProduct";
import { useHistory } from "react-router-dom";
import {DateUtils} from "../../services/Utils";


const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;

    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }

    case 'UPDATE_PRODUCT': {
        const myevents = event.target
        return {...profile, myevents}
    }          

    case 'UPDATE_CHAMP_PRODUCT': {
      const mychampevents = event.target
      return {...profile, mychampevents}
  }
  
  case 'UPDATE_CUP_PRODUCT': {
    const mycupevents = event.target
    return {...profile, mycupevents}
  } 

  case 'UPDATE_OTHER_PRODUCT': {
    const myotherevents = event.target
    return {...profile, myotherevents}
  }       

  case 'UPDATE_COMPETITION_SCHEDULE': {
    //debugger
      const myschedule = event.schedule
      const compid = event.id
      let mycompetitions = profile.mycompetitions

      let objIndex = mycompetitions.findIndex((obj => obj.id === compid));
      mycompetitions[objIndex].myevents=myschedule
      return {...profile, mycompetitions}
  }


  case 'UPDATE_COMPETITION_STATE': {
    //debugger
    const mycompetitionid = event.id
    let name = event.name
    let value = event.value
    let mycompetitions;
    if(!profile.mycompetitions){
      mycompetitions = []

      mycompetitions.push({id:mycompetitionid ,[name]:value})
    } else{
      mycompetitions = profile.mycompetitions
      let objIndex = mycompetitions.findIndex((obj => obj.id === mycompetitionid));
      if(objIndex===-1)
        mycompetitions.push({id:mycompetitionid ,[name]:value})
      else
        mycompetitions[objIndex][name]=value
    }

    return {...profile, mycompetitions}
}    
  
  case 'REMOVE_COMPETITION_SCHEDULE': {
    const removeid = event.id

    if(profile.mycompetitions){
    let mycompetitions = profile.mycompetitions.filter(el => el.id !== removeid)
    return {...profile, mycompetitions}
    }

  }   
    default:
      return profile;
  }  

 }

 const initialState = {};


const ProfileTalentForm = () => {
  console.log('RENDER ProfileTalentForm')

  
  const [profile, dispatch] = useReducer(formReducer, initialState);
  const [profileloader, setProfileloader] = useState(false)

  const [user, setUser] = useState()

  const [loader, setLoader] = useState(false)

  const [sports, setSports] = useState([])

  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])

  // const [type, setType] = useState()

  const history = useHistory();

  const [errorname, setErrorname] = useState()
  const [errorcity, setErrorcity] = useState()
  const [errorbirthdate, setErrorbirthdate]=useState()
  const [errorachievements, setErrorachievements]=useState()

  // const [errorhistory, setErrorhistory] = useState()
  // const [errorwebsite, setErrorwebsite] = useState()
  const [errorabout, setErrorabout] = useState(false)
  const [errorregion, setErrorregion] = useState(false)
  const [errorcountry, setErrorcountry] = useState(false)
  const [errorlogo, setErrorlogo] = useState()
  const [errorsocial, setErrorsocial] = useState()
  const [erroregame, setErroregame] = useState()
  const [errortournamentsnumber, setErrortournamentsnumber] = useState()

  const [errorcontact, setErrorcontact] = useState()
  const [errorfixed, setErrorfixed] = useState()
  const [errormobile, setErrormobile] = useState()
  const [erroremail, setErroremail] = useState()
  const [errorscheduleonline, setErrorscheduleonline] = useState()
  const [errorschedulemanual, setErrorschedulemanual] = useState()

  const [competitiontypes, setCompetitiontypes] = useState([])
  const [mycompetitions, setMycompetitions] = useState([])
  const [errormycompetitions, setErrormycompetitions] = useState()


  const [success, setSuccess] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault();

    const notemptyError = {content: 'Cannot be empty', pointing: 'below'}
    let errors = false

    //**** OVERVIEW ******
    if(profile.logo==null){
      setErrorlogo(notemptyError)
      errors = true
    }

    if(profile.country==null){
      setErrorcountry(notemptyError)
      errors = true
    }    

    if(profile.region==null){
      setErrorregion(notemptyError)
      errors = true
    }        


    if(profile.city==null){
      setErrorcity(notemptyError)
      errors = true
    }            


    if(profile.birthdate != null && !DateUtils.dateIsValid(profile.birthdate)){
      setErrorbirthdate({content: 'Not valid format', pointing: 'below'})
      errors = true
    }    


    if(profile.history==null){
      setErrorabout(notemptyError)
      errors = true
    }                

    if(profile.achievements==null){
      setErrorachievements(notemptyError)
      errors = true
    }                    

    //**** PRODUCT ******
    if(user.customertype ==='SOCIAL MEDIA INFLUENCERS'){
      if(!profile.website && !profile.fb && !profile.instagram && !profile.twitter && !profile.linkedin && !profile.youtube && !profile.tiktok && !profile.twitch && !profile.spotify && !profile.itunes && !profile.amaz && !profile.soundcl){
        setErrorsocial(notemptyError)
        errors = true
      }
    }

    if(user.customertype ==='E-GAMERS'){
      if(profile.egame==null){
        setErroregame(notemptyError)
        errors = true
      }

      if(profile.tournamentsnumber==null){
        setErrortournamentsnumber(notemptyError)
        errors = true
      }    
      
      if(profile.myevents==null || profile.myevents.length === 0){
        setErrorschedulemanual(notemptyError)
        errors = true
      }      
    }


    //******** PRODUCT */
    if(user.customertype ==='ATHLETES' ||user.customertype === 'ACTORS'||user.customertype === 'MUSIC TALENTS'){
      if(profile.mycompetitions==null || profile.mycompetitions.length ===0){
        setErrormycompetitions(notemptyError)
        errors = true
      }
    }    

    //******** CONTACT */
    if(profile.contactperson==null){
      setErrorcontact(notemptyError)
      errors = true
    }                
    
    if(profile.contactemail==null){
      setErroremail(notemptyError)
      errors = true
    }                
    
    if(profile.contactfixednumber==null){
      setErrorfixed(notemptyError)
      errors = true
    }                
    
    if(profile.contactmobilenumber==null){
      setErrormobile(notemptyError)
      errors = true
    }    
    

    if(errors){
      return
    }        

    setLoader(true)
    userService.updateCustomerProfile(profile).then(()=> {
      setLoader(false)
      setSuccess(true)
    })
    .catch(error => {
      // console.log(error.response)
            history.push("/error500");
    })    

  };


  const addCompetition = () => {
    let newfield = { id:mycompetitions.length+1, name: ''}
    setMycompetitions([...mycompetitions, newfield])   
    setErrormycompetitions(undefined) 
  }


  const removeCompetition = (id) => {
    setMycompetitions(prev => prev.filter((c) => c.id !== id))  
    dispatch({type:"REMOVE_COMPETITION_SCHEDULE", id:id});
  }



  const haserrors = () => errorname || errorlogo || errorabout || errorcountry || errorregion || errorcity || errorbirthdate || errorachievements ||
                          errorsocial ||
                          erroregame || errortournamentsnumber || errorschedulemanual ||
                          errormycompetitions ||
                          errorcontact || errorfixed || errormobile || erroremail    


  // const onChangeInput = (event, data) => {
  //   console.log(data.value.length)

  //   let valid = true

  //   // let regex = new RegExp("^[a-zA-Z0-9\r?\n$@$!%*?&#(),'^-_. +]+$");
  //   let regex = new RegExp("^[\-\={}\\[\\]`~;:\\\\|<>/\"a-zA-Z0-9\r?\n$@$!%*?&#(),'^-_. +]+$");
      

  //   dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
  // }
  
    

  // const [fetchloader, setFetchloader] = useState(false)

  useEffect(() => {
    // setFetchloader(true)
    setProfileloader(true)


    const user = authService.getCurrentUser();
    setUser(user)

    if (user) {
      userService.getCustomerProfile().then((response) => {
      
        const myprofile = response.data;


        // if(user.customertype === 'E-GAMERS'){
        //   if(myprofile.myevents){
        //     let x = myprofile.myevents.map((ev,index) => { return {'name':ev} })
        //     myprofile.myevents = x;
        //     setMyevents(myprofile.myevents)
        //   }
    
        // }

        if(user.customertype === 'ATHLETES' ||user.customertype === 'ACTORS'||user.customertype === 'MUSIC TALENTS'){
          let arr = myprofile.mycompetitions
          if(arr && arr.length > 0){
            arr.forEach( (element,index) => {
              if(element.myevents && element.myevents.length>0){
                let x = element.myevents.map((ev,index) => { return {'name':ev} })   
                element.myevents = [...x]
              }
            });
            setMycompetitions(myprofile.mycompetitions)
          }

          if(user.customertype === 'ATHLETES')
            userService.getCompetitionTypes().then((response)=> setCompetitiontypes(response.data))  

          if(user.customertype === 'ACTORS')
            userService.getShowTypes().then((response)=> setCompetitiontypes(response.data))
            
          if(user.customertype === 'MUSIC TALENTS')
            userService.getMusicTypes().then((response)=> setCompetitiontypes(response.data))            
        } 

      dispatch({ type:FETCH_STATE, payload: response.data });


        // setType(myprofile.customertype)

        if(user.customertype === 'ATHLETES'){
          userService.getOrgOptionsSpec(user.customertypeid).then( response => setSports(response.data) )
        }

        userService.getCountries().then((response)=> {
          setCountries(response.data)
          if(myprofile.country)
          userService.getRegions(myprofile.country).then((response)=> setRegions(response.data))          
        });
        // setFetchloader(false)
      })
      .catch(error => {
        // console.log(error.response)
              history.push("/error500");
      })      
      .finally(()=>{  setProfileloader(false)})
      

    }
  }, []);


  console.log('profile',user)
  return   (
    <Container style={{ paddingBottom: '5em' }} text>

    {profileloader &&
      <Dimmer active inverted>
        <Loader inverted>Loading profile</Loader>
      </Dimmer>
      }

      {profile.customerid && 
    <Form 
        onSubmit={handleSubmit} 
        loading={loader || !profile.customerid}
        success={success}
        error={haserrors()}        
        >

      <SegmentOverview
        profile={profile}
        user={user}        
        countries={countries}
        regions={regions}
        setRegions={setRegions}
        sports={sports}
        photo='photo'
        dispatch={dispatch}

        errorname={errorname}
        setErrorname={setErrorname}        
        errorlogo={errorlogo}
        setErrorlogo={setErrorlogo}
        errorcountry={errorcountry}
        setErrorcountry={setErrorcountry}
        errorregion={errorregion}
        setErrorregion={setErrorregion}
        errorcity={errorcity}
        setErrorcity={setErrorcity}
        errorabout={errorabout}
        setErrorabout={setErrorabout}
        errorbirthdate={errorbirthdate}
        setErrorbirthdate={setErrorbirthdate}
        errorachievements={errorachievements}
        setErrorachievements={setErrorachievements}                 
      />

      {user && user.customertype === 'SOCIAL MEDIA INFLUENCERS'?
      <Segment raised>
        <Label as='a' color='green' ribbon>
            Product
          </Label>           

           <ProfileSocialMediaForm 
            profile={profile}
            // errorwebsite={errorwebsite}
            dispatch={dispatch}
            user={user}
            info={true}

            errorsocial={errorsocial}
            setErrorsocial={setErrorsocial}

            web={true}
            fb={true}
            insta={true}
            twitter={true}
            link={true}
            youtube={true}
            tiktok={true}
            twitch={true}
            spotify={true}
          /> 
 
        </Segment>
        :<></>
        }

      {user && user.customertype === 'ATHLETES'?
        <Segment raised>
          <Label as='a' color='brown' ribbon>
              Product
            </Label>                

            {errormycompetitions?
            <div style={{marginTop:'1em'}}>
        
              <Message
                color='red'
                icon='warning'
                content='No competions found!'
              />                              
            </div>
            :<></>}

            {mycompetitions.map((c, index) =>         
            <Segment color='orange' key={c.id} >
              <ProductCompetition 
                  profile={profile}
                  user={user}        
                  dispatch={dispatch}     
                  
                  competitiontypes={competitiontypes}
                  mycompetitions={mycompetitions}
                  setMycompetitions={setMycompetitions}
                  compel={c}
                  setErrormycompetitions={setErrormycompetitions}
              />

              <div style={{marginTop:'1em'}}>
                <Button type='button' basic  onClick={() => removeCompetition(c.id)} icon > Remove Product<Icon name='remove' color={'red'} /></Button>          
              </div>
            </Segment>
            )}

            <div style={{marginTop:'1em'}}>
            <Button type='button' onClick={addCompetition}> Add Product</Button>
            </div>

          </Segment>      

        :<></>}


      {user && (user.customertype === 'ACTORS'||user.customertype === 'MUSIC TALENTS')?
        <Segment raised>
        <Label as='a' color='brown' ribbon>
            Product
          </Label>                

          {errormycompetitions?
          <div style={{marginTop:'1em'}}>
      
            <Message
              color='red'
              icon='warning'
              content='No competions found!'
            />                              
          </div>
          :<></>}

          {mycompetitions.map((c, index) =>         
          <Segment color='orange' key={c.id} >
            <ProductCompetition 
                profile={profile}
                user={user}        
                dispatch={dispatch}     
                
                competitiontypes={competitiontypes}
                mycompetitions={mycompetitions}
                setMycompetitions={setMycompetitions}
                compel={c}

                setErrormycompetitions={setErrormycompetitions}
            />

            <div style={{marginTop:'1em'}}>
              <Button type='button' basic  onClick={() => removeCompetition(c.id)} icon > Remove Product<Icon name='remove' color={'red'} /></Button>          
            </div>
          </Segment>
          )}

          <div style={{marginTop:'1em'}}>
          <Button type='button' onClick={addCompetition}> Add Product</Button>
          </div>

        </Segment>      

        :<></>
        }        




      {user && user.customertype === 'E-GAMERS'?

        <SegmentEgameProduct 
          profile={profile}
          user={user}        
          dispatch={dispatch}
          
          erroregame={erroregame}
          setErroregame={setErroregame}          
          errortournamentsnumber={errortournamentsnumber}                              
          setErrortournamentsnumber={setErrortournamentsnumber}                              
          errorscheduleonline={errorscheduleonline}
          setErrorscheduleonline={setErrorscheduleonline}
          errorschedulemanual={errorschedulemanual}
          setErrorschedulemanual={setErrorschedulemanual}        
        />

      :<></>}        
        
      {user && (user.customertype !== 'SOCIAL MEDIA INFLUENCERS') ?
      <Segment raised>
        <Label as='a' color='yellow' ribbon>
            Audience
          </Label>                

          <ProfileSocialMediaForm 
            profile={profile}
            // errorwebsite={errorwebsite}
            dispatch={dispatch}

            info={false}
            errorsocial={errorsocial}
            setErrorsocial={setErrorsocial}

            web={true}
            fb={true}
            insta={true}
            twitter={true}
            linkedin={true}
            youtube={true}
            tiktok={true}
            twitch={true}
            spotify={true}
            itunes={user.customertype !== 'ATHLETES' && user.customertype !== 'ACTORS' && user.customertype !== 'E-GAMERS'}
            amaz={user.customertype !== 'ATHLETES' && user.customertype !== 'ACTORS' && user.customertype !== 'E-GAMERS'}
            soundcl={user.customertype === 'MUSIC TALENTS'}
            imdb={user.customertype === 'ACTORS'}
            
          /> 

        </Segment>
        :<></>}


      <SegmentContact 
        profile={profile}
        dispatch={dispatch}    

        errorcontact={errorcontact}
        setErrorcontact={setErrorcontact}
        erroremail={erroremail}
        setErroremail={setErroremail}
        errorfixed={errorfixed}
        setErrorfixed={setErrorfixed}
        errormobile={errormobile}
        setErrormobile={setErrormobile}
      />



        <Message
          success
          header='Profile saved successfully.'
          content="Go to 'My Needs' to publish your needs!"
        />        

        <Message
           error
           icon='warning'
           header='Cannot save form!'
           content='Form contains errors'
        />


        <Button type='submit' disabled={haserrors()}> Save </Button>

      </Form>
    }
    
    </Container>
    ) 
  

  };

export default ProfileTalentForm;