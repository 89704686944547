
import { Message} from 'semantic-ui-react'
import { useState, useEffect } from 'react';
import {userService} from '../../services/user.service';
import { useHistory } from "react-router-dom";
import {authService} from '../../services/auth.service'


function ProfileMessages() {
    console.log('ProfileMessages')

    const history = useHistory();
    const [profilestatusresponse, setProfilestatusresponse] = useState()


    useEffect(() => {
          userService.getProfileStatus()
            .then((response)=> setProfilestatusresponse(response.data))
            .catch(err => {
                if(err.response.status===401){
                    authService.logout()
                    history.push("/login");
                    window.location.reload();
                }
            })

      }, []);    

    return (
        <div>
        
                {profilestatusresponse && !profilestatusresponse.profilecompleted ? 
                    <Message
                        error
                        icon='warning'
                        header='Your profile is not complete!'
                        content='Please navigate to My Profile and fill in the missing fields otherwise you cannot publish any needs'
                    />
                :<></>
                }

                {profilestatusresponse && profilestatusresponse.profilecompleted && !profilestatusresponse.haspackages ? 
                    <Message
                        error
                        icon='warning'
                        header='You have no packages connected to your profile!'
                        content='Please contact administrator'
                    />
                :<></>
                }        


                {profilestatusresponse && profilestatusresponse.profilecompleted && profilestatusresponse.haspackages && profilestatusresponse.expiredpackages.length > 0 ? 
                    <div>
                        <Message
                            error
                            icon='warning'
                            header='You have expired packages connected to your profile!'
                        />

                        <b>Expired packages:</b>
                        <ul>
                        {profilestatusresponse.expiredpackages.map(p => <li>{p}</li>)}
                        </ul>
                    </div>
                :<></>
                }


                {profilestatusresponse && profilestatusresponse.profilecompleted && profilestatusresponse.haspackages && profilestatusresponse.almostexpiredpackages.length > 0 ? 
                    <div>
                        <Message
                            warning
                            icon='warning'
                            header='You have packages that are about to expire in less than one (1) month!'
                        />

                        <b>Packages about to expire:</b>
                        <ul>
                        {profilestatusresponse.almostexpiredpackages.map(p => <li>{p}</li>)}
                        </ul>
                    </div>
                :<></>
                }                                        
        </div>
    )
}

export default ProfileMessages