import {NavLink, withRouter} from 'react-router-dom'
import {Menu, Container, Image} from 'semantic-ui-react'
import {authService}  from "../../services/auth.service";
import { useHistory } from "react-router-dom";



function Navbar(props) {
    const history = useHistory();
    const mypath = props.location.pathname
    const user = props.currentuser

    const logOut = () => {
        authService.logout();
        history.push("/login");
        window.location.reload();
      };
    

    return (
        props.currentuser?(
        <Menu  fixed='top' >
            <Container>
                <Menu.Item as={NavLink} exact to="/" header> <Image size='mini' src='/images/logo.png' style={{ marginRight: '1.5em' }} /> Adthisway </Menu.Item>                

                <Menu.Item active={mypath.includes('profile')} as={NavLink} exact to="/profile">My Profile</Menu.Item>
                <Menu.Item active={mypath.includes('publishneed')} as={NavLink} exact to="/publish">Publish My Need{user.customertype !== 'COMPANY'?'/Offer':<></>}</Menu.Item>                
                <Menu.Item active={mypath.includes('packages')} as={NavLink} exact to="/packages">My Packages</Menu.Item>

                <Menu.Item active={mypath.includes('help')} as={NavLink} exact to="/contact">Contact us</Menu.Item>                                

                <Menu.Item position={'right'} >Logged in as: &nbsp; <b>{props.currentuser.username}</b></Menu.Item>                
                <Menu.Item position={'right'} as={NavLink} onClick={logOut} exact to="/logout" >Logout</Menu.Item>

            </Container>
        </Menu>
        )
        :
        (<></>)
        
    )
}

export default withRouter(Navbar)