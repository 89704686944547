import React, { useState, useEffect } from 'react'
import { Container, Header } from 'semantic-ui-react'
import {authService}  from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import ProfileMessages from './ProfileMessages';




function Home() { 
  console.log('RENDER - Home')

  const history = useHistory();

  const [ready, setReady] = useState(false)


  useEffect(() => {
    const user = authService.getCurrentUser();

    if (!user) {
      history.push("/login");
      window.location.reload();
    } 
    setReady(true)
  }, [history]);




  return ready && (
      
      <Container style={{ marginTop: '3em' }}>
        <Header as='h1'>Welcome to Adthisway Platform</Header>


          <ProfileMessages  />


      </Container>
  )
}

export default Home