import { useState } from "react";
import {authService} from "../../services/auth.service";
import { Form, Button, Grid, Header, Image, Segment, Message, Icon } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";


const Register = () => {
  console.log('RENDER - Register')

  const history = useHistory();

  const search = useLocation().search;
  const userid = new URLSearchParams(search).get('var1');
  const token = new URLSearchParams(search).get('var2');

  const [password, setPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");  
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");





  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };


  const onChangeConfPassword = (e) => {
    const password = e.target.value;
    setConfPassword(password);
  };


  const validatePassword = (password, confpassword) => {
    if(password.length < 8){
      setMessage("Minimum password length should be 8 characters");
      return false
    }

    var lowerCaseLetters = /[a-z]/g;
    if(password.search(lowerCaseLetters) === -1){
      setMessage("Password must contain at least one lower case character");
      return false
    }

    var upperCaseLetters = /[A-Z]/g;
    if(password.search(upperCaseLetters) === -1){
      setMessage("Password must contain at least one upper case character");
      return false
    }
    
    var numbers = /[0-9]/g;
    if(password.search(numbers) === -1){
      setMessage("Password must contain at least one number");
      return false
    }
    
    var symbols = /[!@#$%^&*]/g;
    if(password.search(symbols) === -1){
      setMessage("Password must contain at least one symbol");
      return false
    }    

    if(password !== confpassword){
      setMessage("Passwords do not match");
      return false      
    }

    return true
  }

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    if(validatePassword(password, confpassword)){
      authService.register(userid, password, confpassword, token)
        .then(() => {
            history.push("/login");
            window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response.status )
          if(error.response.status === 400)
            setMessage(error.response.data.msg);
          else
          setMessage("Server cannot process your request. Please contact admin");
        });
    } else {
      setLoading(false);

    }
  };

return (
<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450, textAlign: 'left' }}>
      <Header as='h2' color='blue' >
        <Image src='/images/logo.png' /> Register your password
      </Header>

        <Message warning icon>
          <Icon name='warning' />
            <Message.Content>
              Your password should contain at least:
              <ul>
                <li>one lowercase</li>
                <li>one uppercase</li>
                <li>one symbol</li>
                <li>one number</li>
                <li>and minimum length should be 8 characters</li>
              </ul> 
            </Message.Content>
        </Message>      

        {message?
        <Message error icon>
          <Icon name='warning' />
            <Message.Content>
              {message}
            </Message.Content>
        </Message>              
        :<></>
        }


      <Form size='large' onSubmit={handleLogin} loading={loading}>
        <Segment stacked>
          
        <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='Password'
            type='password'
            onChange={onChangePassword}
          />

          <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='Confirm Password'
            type='password'
            onChange={onChangeConfPassword}
          />

          <Button color='blue' fluid size='large'>
            Register
          </Button>
        </Segment>
      </Form>

    </Grid.Column>
  </Grid>
  );
};

export default Register;