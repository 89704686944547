import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Grid, Header, Image, Segment, Message } from "semantic-ui-react";
import {authService}  from "../../services/auth.service";



const Login = (props) => {
  console.log('RENDER Login')
    

  const setCurrentUser = props.setCurrentUser
  const history = useHistory();
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    authService.login(username, password).then(
      () => {
        const user = authService.getCurrentUser();
        setCurrentUser(user)
        history.push("/home");
        //window.location.reload();
      },
      (error) => {
        // console.log(error.response)
        const response = error.response

        if(!response){
          setMessage("Server does not respond");
        }

        if(error.response.status === 500){
          setMessage("Unknown server error");
        } 
        
        if(error.response.status === 401 && error.response.data.errormsg === 'ACCOUNT_LOCKED'){
          setMessage("Your account is locked.");
        }         
        else {
          setMessage("Invalid username or password");
        }

        setLoading(false);

      }
    );
  };
  

  return (



<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
      
      <Header as='h2' color='blue' textAlign='center'>
        <Image src='/images/logo.png' /> Log-in to your account
      </Header>

      <Form 
        loading={loading}
        size='large' 
        onSubmit={handleLogin}
        error={message.length > 0}
        >
        <Message
              error
              header='Login failed'
              content={message}
            />        
        <Segment stacked>
          
          <Form.Input 
            fluid icon='user' 
            iconPosition='left' 
            placeholder='E-mail address' 
            onChange={onChangeUsername} 
          />

          <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='Password'
            type='password'
            onChange={onChangePassword}
          />

          <Button color='blue' fluid size='large'>
            Login
          </Button>
        </Segment>
      </Form>

      <Message>
        Problem signing-in? <a href='/forgot'>Forgot password</a>
      </Message>      

    </Grid.Column>
  </Grid>
  );
};

export default Login;