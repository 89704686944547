import { Container, Message, Icon } from 'semantic-ui-react'



function Error500() { 
  console.log('RENDER - Error500')

  return (
      
      <Container style={{ marginTop: '3em' }}>
        <Message icon negative>
          <Icon name='warning'  />
          <Message.Content>
            <Message.Header>
              We are sorry but our server responded with an unexpected error
            </Message.Header>
            <p>
              If problem continues please contact us here: <b>admin@adthisway.com</b>
            </p>
          </Message.Content>
        </Message>
      </Container>
  )
}

export default Error500