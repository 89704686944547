import { Segment, Label } from "semantic-ui-react";
import ProfileSocialMediaForm from "./SocialMediaForm";


const SegmentSocial = (props) => {
  console.log('RENDER SegmentSocial')

  const profile = props.profile
  const dispatch = props.dispatch;


  return   (
    <Segment raised>
      <Label as='a' color='green' ribbon>
        Social & Digital Media
      </Label>    

      <ProfileSocialMediaForm 
        profile={profile}
        dispatch={dispatch}
        web={true}

        fb={true}
        insta={true}
        twitter={true}
        youtube={true}
        tiktok={true}
        linkedin={true}
      />
    </Segment>
    ) 
  };

export default SegmentSocial;