import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Modal, Header, Icon, Message } from "semantic-ui-react";
import { useHistory } from 'react-router-dom'
import {DateUtils} from "../../services/Utils";



const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;
    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }
    default:
      return profile;
  }  
}



const ContractorOfferForm = (props) => {
  console.log('RENDER ContractorOfferForm')

  const initialState = {};

  const pid = props.pid
  const nid = props.nid

  const [open, setOpen] = useState(false)
  const history = useHistory();

  const [loader, setLoader] = useState(false)

  const [offer, dispatch] = useReducer(formReducer, initialState);

  // const [orgoptionsspec, setOrgoptionsspec] = useState([])

  const [profile, setProfile] = useState()
  const [offersaved, setOffersaved] = useState(false)
  const [status, setStatus] = useState()

  //errors
  const [errorservice, setErrorservice] = useState()
  const [errordescription, setErrordescription] = useState()
  const [errorcost, setErrorcost] = useState()
  const [errorfrom, setErrorfrom] = useState()
  const [errorto, setErrorto] = useState()
  const [publisherror, setPublisherror] = useState(false)



  useEffect(() => {
    setLoader(true)
    const user = authService.getCurrentUser();

    if (user) {
      userService.getCustomerProfile().then((response) => {
        const thisprofile = response.data

        // console.log('myprofile-service',thisprofile.serviceobj)
        setLoader(false)

        if(nid){
          userService.getMyNeed(nid).then((response) => {

            const thisoffer = response.data
            dispatch({ type:FETCH_STATE, payload: thisoffer });
            setStatus(thisoffer.active)

            const filtservice = thisprofile.service.filter(s => s.text === thisoffer.service)
            filtservice[0].value='myvalue'
            thisprofile.service = filtservice
            setProfile(thisprofile)
          } )        
      } else {
        setProfile(thisprofile)
      }        
      })


    }
  }, []);


  const haserrors = () => errorservice || errordescription || errorcost || errorfrom || errorto

  const notemptyError = {content: 'Cannot be empty', pointing: 'below'}
  const numberonly = {content: 'Numbers allowed only', pointing: 'below'}
  const notvaliddate = {content: 'Not valid date format', pointing: 'below'}
  const notvaliddateperiod = {content: 'Not valid date period', pointing: 'below'}
  const pastdate = {content: 'Date belongs to the past', pointing: 'below'}



  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = false

    if(offer.service==null){
      setErrorservice(notemptyError)
      errors = true
    }


    if(offer.description==null){
      setErrordescription(notemptyError)
      errors = true
    }

    
    //DEFAULT    
    if(offer.cost==null){
      setErrorcost(notemptyError)
      errors = true
    }    

    if(offer.cost!=null && !DateUtils.isValidNumber( offer.cost)){
      setErrorcost(numberonly)
      errors = true
    }        


    if(offer.from==null){
      setErrorfrom(notemptyError)
      errors = true
    }        

    if(offer.from!=null && !DateUtils.dateIsValid(offer.from)){
      setErrorfrom(notvaliddate)
      errors = true
    }            
    
    if(offer.to==null){
      setErrorto(notemptyError)
      errors = true
    }            

    if(offer.to != null && !DateUtils.dateIsValid(offer.to)){
      setErrorto(notvaliddate)
      errors = true
    }                


    if(offer.to != null && offer.from != null && DateUtils.dateIsValid(offer.to) && DateUtils.dateIsValid(offer.from) && DateUtils.getTimestamp(offer.to) < DateUtils.getTimestamp(offer.from)){
      setErrorto(notvaliddateperiod)
      setErrorfrom(notvaliddateperiod)      
      errors = true
    }


    if(DateUtils.dateIsPast  ( offer.to) ){
      setErrorto(pastdate)
      errors = true
    }           



    if(errors){
      return
    }

    setLoader(true)
    setPublisherror(false)
    setOffersaved(false)

    
    if(!nid){
      userService.createOffer(offer, pid).then(()=> {
        setOffersaved(true)
      })
      .catch(error => {
        // console.log(error.response)
        if(error.response.status === 409){
          setPublisherror('Offer already exists for dates specified')
        }

        if(error.response.status === 500){
          setPublisherror('Server responded with unknown error')
        }        
      })
      .finally(() => {
        setLoader(false)
      })      
    } else {
      userService.updateOffer(offer, nid).then(()=> {
        setOffersaved(true)
        setLoader(false)
      })
      .catch(error => {
        // console.log(error)
        if(error.response && error.response.status === 409){
          setPublisherror('Offer already exists for dates specified')
          return
        }

        if(error.response && error.response.status === 500){
          setPublisherror('Server responded with unknown error')
          return
        }        

        setPublisherror('Cannot reach server')
      })
      .finally(() => {
        setLoader(false)
      })
    }
  };





  const onChangeInput = (event, data) => {
    if(data.name === 'service' && data.value != null){
      setErrorservice(undefined)
    }



    if(data.name === 'description'){
      if(data.value === ''){
        setErrordescription(notemptyError)
        return
      } 


      if(data.value.split(' ').length > 100){
        setErrordescription({content: 'Max 100 words allowed', pointing: 'below'})
        return
      } 


      if(data.value.length > 0 && ! DateUtils.NO_GREEK_REGEX.test(data.value)){ 
        setErrordescription({content: 'English only', pointing: 'below'})
        return
      }     


      setErrordescription(undefined)
    }     




    if(data.name === 'cost'){
      if(data.value === ''){
        setErrorcost(notemptyError)
        return
      } 

      if(!DateUtils.isValidNumber(data.value)){
        setErrorcost(numberonly)
        return
      }       

      setErrorcost(undefined)
    } 



    // FROM date period        
    if(data.name === 'from'){
      if(data.value === ''){
        setErrorfrom(notemptyError)
        return
      } 

      if(!DateUtils.dateIsValid( data.value) ){
        setErrorfrom(notvaliddate)
        return
      }       


      if(DateUtils.dateIsPast  ( data.value) ){
        setErrorfrom(pastdate)
        return
      }       



      if(offer.to!=null && DateUtils.dateIsValid(offer.to) && DateUtils.dateIsValid(data.value) && DateUtils.getTimestamp(offer.to) < DateUtils.getTimestamp(data.value)){
        setErrorto(notvaliddateperiod)
        setErrorfrom(notvaliddateperiod)      
        // return
      }      

      if(offer.to!=null && DateUtils.dateIsValid(offer.to) && DateUtils.dateIsValid(data.value) && DateUtils.getTimestamp(offer.to) >= DateUtils.getTimestamp(data.value)){
        setErrorto(undefined)
        setErrorfrom(undefined)      
        
      }            

      setErrorfrom(undefined)
    }    
    
    

    // TO date period        
    if(data.name === 'to'){
      if(data.value === ''){
        setErrorto(notemptyError)
        return
      } 

      if(!DateUtils.dateIsValid( data.value) ){
        setErrorto(notvaliddate)
        return
      }      


      if(DateUtils.dateIsPast  ( data.value) ){
        setErrorto(pastdate)
        return
      }             
      
      if(offer.from!=null && DateUtils.dateIsValid(data.value) && DateUtils.dateIsValid(offer.from) && DateUtils.getTimestamp(data.value) < DateUtils.getTimestamp(offer.from)){
        setErrorto(notvaliddateperiod)
        setErrorfrom(notvaliddateperiod)      
        // return
      }            

      if(offer.from!=null && DateUtils.dateIsValid(data.value) && DateUtils.dateIsValid(offer.from) && DateUtils.getTimestamp(data.value) >= DateUtils.getTimestamp(offer.from)){
        setErrorto(undefined)
        setErrorfrom(undefined)      
      }                  
      
      setErrorto(undefined)
    }        


    dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
  }




  // console.log('myprofile', profile)
  // console.log('myoffer',offer)

  return (

    <Container >

      <h4>Fill in your offer form </h4>


        {offersaved &&
          <Message icon success>
             <Icon name='warning'  />
             <Message.Content>
            <Message.Header>
              Your offer has been saved successfully!
            </Message.Header>
            <p>
              Navigate to <b>My Packages</b> to see your needs
            </p>
            </Message.Content>
          </Message>
        }                    

      <Form 
        onSubmit={handleSubmit} 
        loading={loader} 
        error={haserrors() || publisherror}
        >

      
      {profile && profile.service.length > 0 ?
          <Form.Select 
            placeholder='Select Offered service' 
            width={5}
            required 
            name='service'
            label='Select Offered service' 
            // defaultValue={offer.service} 
            defaultValue={'myvalue'} 
            options={profile.service}  
            onChange={onChangeInput} 
            error={errorservice}
          />:<></>
          
        }


        <Form.TextArea className='required' 
          label='Description'
          width={10}
          defaultValue={offer.description} 
          placeholder='Short description'
          name='description'
          error={errordescription}
          onChange={onChangeInput} 
        />        


          <Form.Input className='required'
            width={5} 
            label='Cost'
            defaultValue={offer.cost} 
            placeholder='Cost' 
            name='cost'
            onChange={onChangeInput} 
            error={errorcost}
          />


          <Form.Input className='required'
            width={5} 
            label='Period from'
            defaultValue={offer.from} 
            placeholder='DD-MM-YYYY' 
            name='from'
            onChange={onChangeInput} 
            error={errorfrom}
          />

          <Form.Input className='required'
            width={5} 
            label='Period to'
            defaultValue={offer.to} 
            placeholder='DD-MM-YYYY' 
            name='to'
            onChange={onChangeInput} 
            error={errorto}
          />

        {status !== undefined &&
          <Form.Group inline>
          <label className='required'>{status?'Active':'Disabled'}</label>
            <Form.Radio 
              toggle
              value={'on'}
              color={'green'}
              checked={status}
              name='active'
              onChange={()=> {
                
                if(!status && DateUtils.dateIsPast(offer.to) ){
                  setErrorto(pastdate)
                  return
                }                                             
                setStatus(!status)

                dispatch({type:UPDATE_STATE, name: 'active', value: !offer.active});
              }}
            />
            </Form.Group>
        }          

          <Message
           error
           icon='warning'
           header='Cannot publish offer!'
           content={publisherror?publisherror:'Form contains errors'}
          />

          <Button type='submit' disabled={haserrors()}> Submit </Button>

          {nid &&
              <Modal 
                  closeIcon
                  open={open}
                  trigger={<Button type='button' style={{marginLeft:'13em'}} color='red'>Delete</Button>}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}>
                
                <Header icon='warning' content='Delete offer' />

                <Modal.Content>
                  <p> Are you sure you want to delete this offer ? </p>
                </Modal.Content>
                
                <Modal.Actions>
                  <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> No
                  </Button>

                  <Button color='green' onClick={() => {
                      setLoader(true)
                      userService.deleteMyOffer(pid, nid).then(()=> {

                        history.goBack();
                      })

                      setOpen(false)
                    }}>
                    <Icon name='checkmark' /> Yes
                  </Button>
                </Modal.Actions>
              </Modal>        
            }                    
      </Form>

    </Container>    
    )
};

export default ContractorOfferForm;