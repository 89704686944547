import {  Form,  Image, Message, Icon } from "semantic-ui-react";




const ProfileSocialMediaForm = (props) => {
  console.log('RENDER ProfileSocialMediaForm')

  const user = props.user;
  const profile = props.profile;
  const dispatch = props.dispatch;

  const web = props.web
  const fb = props.fb
  const insta = props.insta
  const twitter = props.twitter
  const link = props.linkedin
  const yout = props.youtube
  const tiktok = props.tiktok
  const twitch = props.twitch
  const spotify = props.spotify
  const itunes = props.itunes
  const amaz = props.amaz
  const imdb = props.imdb
  const soundcl = props.soundcl

  const info=props.info
   const errorsocial=props.errorsocial
   const setErrorsocial=props.setErrorsocial
  // const [errorsocial, setErrorsocial]=useState()
  // const [errorwebsite, setErrorwebsite] = useState()



  const onChangeInput = (event, data) => {
    if(setErrorsocial) setErrorsocial(false)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }

  const attributes = {
    color: 'grey'
  }

  if(user && user.customertype === 'SOCIAL MEDIA INFLUENCERS' && errorsocial){
    attributes.color = 'red'
  }

  return   (
    <div>

    <div style={{marginBottom:'1em', marginTop:'1em'}}>
      {info ? 
    <Message {...attributes}>
      <Icon name='warning' />
        Please fill-in your online channels. (At least one)
    </Message>
    :<></>}
    </div>

        {web? 
        <Form.Input 
            fluid   
            icon='linkify'
            iconPosition='left' 
            labelPosition='left'
            placeholder='Web site' 
            defaultValue={profile.website}
            name='website'
            // error={ errorwebsite}
            onChange={onChangeInput} 
        />
        :<></>}

      {insta?
        <Form.Group inline>
         <Image avatar src='/images/insta.png' />
         <Form.Input 
            fluid 
            width={15} 
            placeholder='instagram' 
            defaultValue={profile.instagram}
            name='instagram'
            onChange={onChangeInput} 
          />          
        </Form.Group>
        :<></>}


        {fb ?
        <Form.Group inline>
          <Image avatar src='/images/fb.png' />
          <Form.Input 
              fluid  
              placeholder='facebook' 
              defaultValue={profile.fb}
              name='fb'
              width={15}
              onChange={onChangeInput} 
          />
        </Form.Group>
        :<></>}


        {twitter?
        <Form.Group inline>
            <Image avatar src='/images/twitter.jpg' />
            <Form.Input 
              fluid             
              width={15}  
              placeholder='twitter' 
              defaultValue={profile.twitter}
              name='twitter'
              onChange={onChangeInput} 
            />                    
        </Form.Group>
        :<></>}


       {yout?
        <Form.Group inline>
            <Image avatar src='/images/youtube.jpg' />
            <Form.Input 
              fluid width={15} 
              placeholder='youtube' 
              defaultValue={profile.youtube}
              name='youtube'
              onChange={onChangeInput} 
            />                     
        </Form.Group>
        :<></>}

      {tiktok?
        <Form.Group inline>
            <Image avatar src='/images/tiktok.jpg' />
            <Form.Input 
              fluid width={15} 
              placeholder='tiktok' 
              defaultValue={profile.tiktok}
              name='tiktok'
              onChange={onChangeInput} 
            />                     
        </Form.Group>
        :<></>}


        {link? 
        <Form.Group inline>
            <Image avatar src='/images/linkedin.jpg' />
            <Form.Input 
              fluid width={15} 
              placeholder='linkedin' 
              defaultValue={profile.linkedin}
              name='linkedin'
              onChange={onChangeInput} 
            />                    
        </Form.Group>
        :<></>}



        {twitch?
        <Form.Group inline>
            <Image avatar src='/images/twitch.jpg' />
            <Form.Input 
              fluid width={15} 
              placeholder='twitch' 
              defaultValue={profile.twitch}
              name='twitch'
              onChange={onChangeInput} 
            />                     
        </Form.Group>
        :<></>}


       {spotify?
        <Form.Group inline>
            <Image avatar src='/images/spotify.jpg' />
            <Form.Input 
              fluid width={15} 
              placeholder='spotify' 
              defaultValue={profile.spotify}
              name='spotify'
              onChange={onChangeInput} 
            />                     
        </Form.Group>                    
        :<></>}

        {itunes?
        <Form.Group inline>
            <Image avatar src='/images/itunes.png' />
            <Form.Input 
              fluid width={15} 
              placeholder='itunes' 
              defaultValue={profile.itunes}
              name='itunes'
              onChange={onChangeInput} 
            />                     
        </Form.Group>
        :<></>}

        {amaz?
        <Form.Group inline>
            <Image avatar src='/images/amazon.png' />
            <Form.Input 
              fluid width={15} 
              placeholder='amazon' 
              defaultValue={profile.amaz}
              name='amaz'
              onChange={onChangeInput} 
            />                     
        </Form.Group>                              
        :<></>}

        {imdb?
        <Form.Group inline>
            <Image avatar src='/images/imdb.png' />
            <Form.Input 
              fluid width={15} 
              placeholder='imdb' 
              defaultValue={profile.imdb}
              name='imdb'
              onChange={onChangeInput} 
            />                     
        </Form.Group>                              
        :<></>}        


          {soundcl?
        <Form.Group inline>
            <Image avatar src='/images/soundcloud.jpg' />
            <Form.Input 
              fluid width={15} 
              placeholder='soundcloud' 
              defaultValue={profile.soundcl}
              name='soundcl'
              onChange={onChangeInput} 
            />                     
        </Form.Group>                                                  
        :<></>}
      </div>
    ) 
  

  };

export default ProfileSocialMediaForm;