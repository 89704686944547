import {   Image,  Grid, Segment, Table,  Header, Label, List,  Item, TableRow, TableCell, GridRow } from "semantic-ui-react";
import {config} from '../../Config'



const OrgMediaCard = (props) => {
  console.log('RENDER OrgMediaCard')

  const customer = props.customer

  const mywidth = 5



  
console.log('talent', customer)
  return customer ?  (

      <div>

      <Header as='h2'>
        <Image src={ `${config.url.API_BASE_URL}/customers/${customer.customerid}/image/logo`}  />  {customer.name}
      </Header>

      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='red' ribbon>
                Overview
              </Label>      


              <Table fixed>

                <Table.Row>
                  <Table.Cell>
                    <b>Country</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.country}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Region</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.region}
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>City</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.city}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>CEO</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.ceo}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>Date of establishment</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.establishmentdate}
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>
          </Grid.Column>

          <Grid.Column>
          <Item>
                        <Item.Content>
                        <Item.Meta><b>About</b></Item.Meta>
                        <Item.Description>
                      {customer.history}
                      </Item.Description>
                      </Item.Content>
                      </Item>   
          </Grid.Column>


          <Grid.Column>
          <Item>
                        <Item.Content>
                        <Item.Meta><b>Achievements</b></Item.Meta>
                        <Item.Description>
                      {customer.achievements}
                      </Item.Description>
                      </Item.Content>
                      </Item>                          

          </Grid.Column>        
        </Grid.Row>


              <Grid.Row>
        <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='purple' ribbon>
                Audience
              </Label>      
              <Table>


                <TableRow>
                  <TableCell>
                  <b>analytics</b>
                  </TableCell>
                  <TableCell>
                  {customer.audience1}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Audience</b>
                  </TableCell>
                  <TableCell>
                  {customer.audience}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Markets (countries)</b>
                  </TableCell>
                  <TableCell>
                  {customer.audience2}
                  </TableCell>
                </TableRow>                                                                

              </Table>
              </Segment>
              </Grid.Column>







              </Grid.Row>   





        <Grid.Row>
        <Grid.Column>

          </Grid.Column>     
          </Grid.Row>

          <GridRow>                     
                    <Grid.Column>
          <Segment>
              <Label as='a' color='brown' ribbon>
                Product
              </Label>    


              <Table color={'brown'}>


                {customer.scheduleonline &&
                <Table.Row>
                  <Table.Cell>
                  <Item>
                        <Item.Content>
                        <Item.Meta><b>Schedule URL</b></Item.Meta>
                        <Item.Description>
                        {customer.scheduleonline}
                      </Item.Description>
                      </Item.Content>
                      </Item>                          
                    </Table.Cell>                    
                </Table.Row>
                }

              {!customer.scheduleonline &&                
                <Table.Row>
                  <Table.Cell>
                  <Item>
                        <Item.Content>
                        <Item.Meta><b>Schedule</b></Item.Meta>
                        <Item.Description>
                          <List>
                       {customer.myevents.map(e => <List.Item>  <List.Icon name='angle right' /> <List.Content> {e} </List.Content>  </List.Item>)} 
                      </List>
                      </Item.Description>
                      </Item.Content>
                      </Item>                          
                    </Table.Cell>                    
                </Table.Row>                
                }


            </Table>
            
            </Segment>


          </Grid.Column>





          <Grid.Column width={mywidth}>
          <Segment>
              <Label as='a' color='green' ribbon>
                Social Media
              </Label>    


              <Table>

                <Table.Row>
                  <Table.Cell>
                    <b>website</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={customer.website}>{customer.website}</a>
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>facebook</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.fb}
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>instagram</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.instagram}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>twitter</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.twitter}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>tiktok</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.tiktok}
                    </Table.Cell>                    
                </Table.Row>                
            </Table>
            </Segment>


          </Grid.Column>

          <Grid.Column width={mywidth}>

 

            <Segment>
              <Label as='a' color='blue' ribbon>
                Contact Details
              </Label>                  


              <Table>

                <Table.Row>
                  <Table.Cell>
                    <b>Contact Person</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactperson}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Email</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactemail}
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>Fixed Number</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactfixednumber}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>Mobile Number</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactmobilenumber}
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>                                 

          </Grid.Column>          

          <Grid.Column width={mywidth}>


          </Grid.Column>                    
        </GridRow>

      </Grid>

      </div>
  ) : <></>;
};

export default OrgMediaCard;