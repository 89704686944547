
import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect } from "react";
import { Container, Segment, Loader, Dropdown, Message, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import OrganisationNeedForm from "./OrganisationNeedForm";
import OrganisationOfferForm from "./OrganisationOfferForm";
import ContractorNeedForm from "./ContractorNeedForm";
import TalentNeedForm from "./TalentNeedForm";
import ContractorOfferForm from "./ContractorOfferForm";




const PublishForm = () => {
  console.log('RENDER PublishForm')
  
  const [customer, setCustomer] = useState()
  const [mypackages, setMypackages] = useState()
  const [mypackage, setMypackage] = useState()
  const [pid, setPid] = useState()
  const [publish, setPublish] = useState()

  const [loadpackage, setLoadpackage] = useState(false)
  const [profilestatusresponse, setProfilestatusresponse] = useState()


  const onPackageSelect = (e, data) => {
    setPublish(null)
    setLoadpackage(true)
    setPid(data.value)
    userService.getMyPackage(data.value).then((response)=> {
      setMypackage(response.data)
      setLoadpackage(false)
    })
  };


  const onNeedSelect = (e, data) => {
    console.log(data.value)
    setPublish(data.value)
  };

  const history = useHistory();

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      setCustomer(user)

      userService.getProfileStatus()
      .then((response)=> setProfilestatusresponse(response.data))
      .catch(err => {
          if(err.response.status===401){
              authService.logout()
              history.push("/login");
              window.location.reload();
          }
      })


      userService.getCustomerPackagesIds().then((response) => setMypackages(response.data) )
      .catch(error => {
        // console.log(error.response)
        if(error.response.status === 401){
          authService.logout();
          history.push("/login");
          window.location.reload();
        } else {
            history.push("/error500");
        }
      })            

    }
  }, []);

    

  return mypackages?  (
    <Container style={{ marginTop: '1em', marginBottom: '1em' }}  >
    
    {profilestatusresponse && !profilestatusresponse.profilecompleted ? 
                    <Message
                        error
                        icon='warning'
                        header='Your profile is not complete!'
                        content='Please navigate to My Profile and fill in the missing fields otherwise you cannot publish any needs'
                    />
                :<></>
                }


    {mypackages.length > 0 ?
      <div style={{width:'35em'}}>
        <h4>Select your package</h4>
        <Dropdown
          placeholder='Select Package'
          fluid
          selection
          onChange={onPackageSelect} 
          options={mypackages}
          disabled={profilestatusresponse && !profilestatusresponse.profilecompleted}
        />
      </div>
      :(
        <Message icon warning>
             <Icon name='warning'  />
             <Message.Content>
            <Message.Header>
              No packages have been found connected to your profile or packages have been expired
            </Message.Header>
            <p>
              For more infor please contact us here: <b>info@adthisway.com</b>
            </p>
            </Message.Content>
          </Message>        
      )}

    {mypackage?

    <div style={{marginTop:'1em'}}>
      
      <Segment 
        loading={loadpackage} 
      >

      {mypackage && customer.customertype.toUpperCase() === 'COMPANY' && mypackage.target.toUpperCase() === 'CONTRACTOR'  ?
        <ContractorNeedForm pid={pid} nid={mypackage.nid} />
        :<></>
      }      

      {mypackage && customer.customertype.toUpperCase() === 'COMPANY' && mypackage.target.toUpperCase() === 'ORGANIZATION'  ?
        <OrganisationNeedForm pid={pid} nid={mypackage.nid} />
        :<></>
      }

      {mypackage && customer.customertype.toUpperCase() === 'COMPANY' && mypackage.target.toUpperCase() === 'TALENT'  ?
        <TalentNeedForm pid={pid} nid={mypackage.nid} />
        :<></>
      }      


      {mypackage && customer.customertype.toUpperCase() === 'CONTRACTOR' && mypackage.target.toUpperCase() === 'COMPANY' ?
          <ContractorOfferForm pid={pid} nid={mypackage.nid} />
        :<></>
      }


      {mypackage && customer.customertype.toUpperCase() === 'CONTRACTOR' && mypackage.target.toUpperCase() === 'ORGANIZATION' ?
        <div>

          <Dropdown
              placeholder='Select Offer or Need'
              fluid
              style={{marginBottom:'2em'}}
              selection
              onChange={onNeedSelect} 
              options={[
                {"key":"offer", "value":"offer", "text":"Publish an offer to Organisations"}, 
                {"key":"need", "value":"need", "text":"Publish a need for Organisations"}]}
            />

            {publish && publish === 'offer'?
              <ContractorOfferForm pid={pid} nid={mypackage.nid} /> 
              :
              <></>
            }

            {publish && publish === 'need'?
              <OrganisationNeedForm pid={pid} nid={mypackage.nid} />
              :
              <></>
            }          

        </div>      

        :<></>
      }


      {mypackage && customer.customertype.toUpperCase() === 'CONTRACTOR' && mypackage.target.toUpperCase() === 'TALENT' ?
        <div>

          <Dropdown
              placeholder='Select Offer or Need'
              fluid
              style={{marginBottom:'2em'}}
              selection
              onChange={onNeedSelect} 
              options={[
                {"key":"offer", "value":"offer", "text":"Publish an offer to Talents"}, 
                {"key":"need", "value":"need", "text":"Publish a need for Talents"}]}
            />

            {publish && publish === 'offer'?
              <ContractorOfferForm pid={pid} nid={mypackage.nid} /> 
              :
              <></>
            }

            {publish && publish === 'need'?
              <TalentNeedForm pid={pid} nid={mypackage.nid} />
              :
              <></>
            }          

        </div>      

        :<></>
      }      


      
      {mypackage && (customer.customerparenttype === 'TALENT' || customer.customerparenttype === 'MEDIA' || customer.customerparenttype === 'CULTURE' || customer.customerparenttype === 'SPORT' ) && mypackage.target.toUpperCase() === 'COMPANY' ?
        <OrganisationOfferForm pid={pid} nid={mypackage.nid} />
        :<></>
      }    



      {mypackage && (customer.customerparenttype === 'TALENT' || customer.customerparenttype === 'MEDIA' || customer.customerparenttype === 'CULTURE' || customer.customerparenttype === 'SPORT' ) && mypackage.target.toUpperCase() === 'CONTRACTOR' ?
        <div>

        <Dropdown
            placeholder='Select Offer or Need'
            fluid
            style={{marginBottom:'2em'}}
            selection
            onChange={onNeedSelect} 
            options={[
              {"key":"offer", "value":"offer", "text":"Publish an offer to Contractors"}, 
              {"key":"need", "value":"need", "text":"Publish a need for Contractors"}]}
          />

          {publish && publish === 'offer'?
            <OrganisationOfferForm pid={pid} nid={mypackage.nid} /> 
            :
            <></>
          }

          {publish && publish === 'need'?
            <ContractorNeedForm pid={pid} nid={mypackage.nid} />
            :
            <></>
          }          

        </div>
        :<></>
      }          

    </Segment>
    </div>
    :<></>
    }

    </Container>
  ) : 
  <div>
      <Container text>
        <Loader active inline >Loading</Loader>
      </Container>
  </div>
};

export default PublishForm;

