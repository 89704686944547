

const ddMMyyyyregex = /^\d{2}-\d{2}-\d{4}$/; // dd-MM-yyyy

const NO_GREEK_REGEX = new RegExp("^[-={}\\[\\]`~;:\\\\|<>/\"a-zA-Z0-9?$–@$!%*?’&’#(),‘'^-_. +]+$");


function dateIsPast(dateStr){
  
  const mydate = strToDate(dateStr)

  const today = new Date()

  if(mydate.getFullYear() < today.getFullYear()){
    return true
  } 
  
  if(mydate.getFullYear() > today.getFullYear()){
    return false
  } 


  if(mydate.getFullYear() === today.getFullYear()){
    if(mydate.getMonth() < today.getMonth()){
      return true
    }

    if(mydate.getMonth() > today.getMonth()){
      return false
    }    

    if(mydate.getMonth() === today.getMonth()){
      return mydate.getDate() < today.getDate()
    }      
  }   


  return mydate.getFullYear() < new Date().getFullYear()

}





function strToDate(dateStr){


  const [day, month, year] = dateStr.split('-');
  const isoFormattedStr = `${year}-${month}-${day}`;

  return new Date(isoFormattedStr);
}




function dateIsValid(dateStr){
  
    if (dateStr ==null || dateStr==='' || dateStr.match(ddMMyyyyregex) === null) {
      return false;
    }

    const [day, month, year] = dateStr.split('-');
    const isoFormattedStr = `${year}-${month}-${day}`;
    const mydate = new Date(isoFormattedStr);
    
    const timestamp = mydate.getTime();
  
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }
  
    return mydate.toISOString().startsWith(isoFormattedStr);
}




function getTimestamp(dateStr){

  const [day, month, year] = dateStr.split('-');

  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${year}-${month}-${day}`;

  const date = new Date(isoFormattedStr);

  return date.getTime();
}




function isValidNumber(numberStr){

  const regex = /^\d*$/;

  return regex.test(numberStr);
}



export const DateUtils = {

  ddMMyyyyregex,
  NO_GREEK_REGEX,

  dateIsValid,
  dateIsPast, 
  isValidNumber,
  getTimestamp,
}