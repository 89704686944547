
import { useState, useEffect } from "react";
import { Form, Button, Icon} from "semantic-ui-react";


const ProductCompetition = (props) => {
  console.log('RENDER ProductCompetition')

  const profile = props.profile
  const dispatch = props.dispatch;
  const user = props.user

  const [schedule, setSchedule] = useState()
  const [myevents, setMyevents] = useState([])

  const competitiontypes = props.competitiontypes
  const compel = props.compel

  const setErrormycompetitions = props.setErrormycompetitions




  const onChangeSchedule = (e, data) => {
    // setErrorschedule(undefined)
    setSchedule(data.value)
    // dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
    dispatch({type:"UPDATE_COMPETITION_STATE", id:compel.id, name:data.name, value:data.value});
  };



  const onChangeInput = (event, data) => {        

    if(data.name === 'scheduleonline' ){
      if(data.value.length === 0){
        // setErrorscheduleonline({content: 'Schedule cannot be empty', pointing: 'below'})
        return
      }      
      // setErrorscheduleonline(undefined)
    } 
    
    if(data.name === 'gamesnr' ){
      if(data.value.length === 0){
        // setErrorgamesnr({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      // setErrorgamesnr(undefined)
    }     

    // console.log('compel',compel)

    // console.log('compel',updcompel)
    // setMyevents({...myevents, updcompel})
    // let newdata = [...mycompetitions]
    // newdata[compel.id][data.name]= data.value

    dispatch({type:"UPDATE_COMPETITION_STATE", id:compel.id, name:data.name, value:data.value});
  }



  useEffect(() => {
    if(compel.myevents && myevents.length === 0)
    setMyevents(compel.myevents)
  }, [compel.myevents])


  
  // console.log('profile',compel)
  // console.log('myevents',myevents)



  const updateEvent = (index, e) => {
    //debugger
    let data = [...myevents];
    data[index][e.target.name] = e.target.value;
    setMyevents(data);    
    // let upd = {...compel, data}
    setErrormycompetitions(undefined)

    dispatch({type:"UPDATE_COMPETITION_SCHEDULE", id:compel.id, schedule: data});
  }
  
  const addEvent = () => {
    let newfield = { name: ''}
    setMyevents([...myevents, newfield])    
  }
  
  const removeEvent = (index) => {
    let data = [...myevents];
    data.splice(index, 1)
    setMyevents(data)  
    dispatch({type:"UPDATE_COMPETITION_SCHEDULE", id:compel.id, schedule: data});
  }
      
  const type = () => {
    if(profile.customertype==='ACTORS')
    return 'Product type'

    if(profile.customertype==='ATHLETES')
    return 'Competition'    
  }


  const type2 = () => {
    if(profile.customertype==='ACTORS' || profile.customertype==='MUSIC TALENTS')
    return 'Product'
    else
    return 'Event'
  }  


  const numberof = () => {
    
    if(profile.customertype==='ACTORS')
    return 'Number of appearances'

    if(profile.customertype==='ATHLETES')
    return 'Number of games'    
    
    if(user.customerparenttype==='SPORT')
    return 'Number of games'        
  }  

  return   (
    <div style={{marginTop:'1em'}} >

        <Form.Select 
            label={type()} 
            placeholder='Select Type' 
            required 
            // error={errorindustry}
            defaultValue={compel.competitiontype} 
            options={competitiontypes}  
            name='competitiontype'
            onChange={onChangeInput} 
        />


        <Form.Input 
            fluid   
            required
            label={numberof()}
            labelPosition='left'
            icon='user'
            placeholder='Number' 
            iconPosition='left'
            width={5}
            defaultValue={compel.gamesnr}
            name='gamesnr'
            // error={ errorgamesnr}
            onChange={onChangeInput} 
        />        
        

       <Form.Select 
        placeholder='Please select where the schedule can be found' 
        required 
        label={type2()+' schedule'}
        options={[
                  {text:'Schedule can be found online', value:'online'},
                  {text:'I will enter schedule manually', value:'manual'}
                ]}  
        name='schedule'
        value={compel.schedule}
        onChange={onChangeSchedule} 
        // error={errorschedule}
      />

      {schedule === 'online'|| compel.schedule === 'online'?
        <Form.Input 
        label={type2()+' schedule url'}
          placeholder='http://mysite.com' 
          defaultValue={compel.scheduleurl}
          name='scheduleurl'
          onChange={onChangeInput} 
          className='required'
          // error={errorscheduleonline}
        />
        :<></>
      }

    
        {schedule === 'manual' || compel.schedule === 'manual' ?
          <div>

            {/* {errorschedulemanual?
              <Message
                error
                icon='warning'
                content='Events are empty!'
              />            
              :<></>
            } */}

            <Button type='button' onClick={addEvent}> Add {type2()}</Button>

            {myevents && myevents.map((el, index) => (
              <Form.Group key={index} style={{marginTop:'1em'}} >

                    <input
                      name='name'
                      required
                      placeholder='Name & Description'
                      value={el.name}
                      onChange={e => updateEvent(index, e)}
                    />
                  <Button type='button' basic  onClick={() => removeEvent(index)} icon > <Icon name='remove' color={'red'} /></Button>
              </Form.Group>
                
                ))}
          </div>
        :<></>
        }
    </div>      
    ) 
  };

export default ProductCompetition;