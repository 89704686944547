
import { useState, useEffect } from "react";
import { Segment, Label, Form, Button, Icon, Message } from "semantic-ui-react";


const SegmentProduct = (props) => {
  console.log('RENDER SegmentProduct')

  const profile = props.profile
  const dispatch = props.dispatch;

  const [schedule, setSchedule] = useState()
  const [myevents, setMyevents] = useState([])

  const user = props.user
  const productType = props.productType
  const errorschedule = props.errorschedule
  const setErrorschedule = props.setErrorschedule
  const errorscheduleonline = props.errorscheduleonline
  const setErrorscheduleonline = props.setErrorscheduleonline
  const errorschedulemanual = props.errorschedulemanual
  const setErrorschedulemanual = props.setErrorschedulemanual  
  const erroreventsnumber = props.erroreventsnumber
  const setErroreventsnumber = props.setErroreventsnumber

  

  const onChangeSchedule = (e, data) => {
    setErrorschedule(undefined)
    setErrorschedulemanual(undefined)
    setErrorscheduleonline(undefined)
    setSchedule(data.value)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  };


  const onChangeInput = (event, data) => {   
    setErrorscheduleonline(undefined)     
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }


  const onChangeEventInput = (event, data) => {   
    if(data.value.length === 0){
      setErroreventsnumber({content: 'Number cannot be empty', pointing: 'below'})
      return
    }

    setErroreventsnumber(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }  


  useEffect(() => {
    if(profile.myevents && myevents.length === 0)
    setMyevents(profile.myevents)
  }, [profile.myevents])

  console.log('profile',profile)
  console.log('myevents',myevents)


  const updateEvent = (index, e) => {
    let data = [...myevents];
    data[index][e.target.name] = e.target.value;
    setMyevents(data);    
    dispatch({type:"UPDATE_PRODUCT", target: data});
  }
  
  const addEvent = () => {
    let newfield = { name: ''}
    setMyevents([...myevents, newfield])    
  }
  
  const removeEvent = (index) => {
    let data = [...myevents];
    data.splice(index, 1)
    setMyevents(data)  
    dispatch({type:"UPDATE_PRODUCT", target: data});
  }
      
console.log(user)
  return   (
    <Segment raised>
      <Label as='a' color='brown' ribbon>
          Product
      </Label>                

    {user.customerparenttype !== 'MEDIA' && 
      <Form.Input className='required'
                fluid   
                label='Number of events'
                labelPosition='left'
                icon='user'
                iconPosition='left'
                width={5}
                defaultValue={profile.eventsnumber}
                name='eventsnumber'
                onChange={onChangeEventInput} 
                error={erroreventsnumber}
            />}


       <Form.Select 
          placeholder='Please select where the schedule can be found' 
          required 
          label={productType+' schedule'}
          options={[
                    {text:'Schedule can be found online', value:'online'},
                    {text:'I will enter schedule manually', value:'manual'}
                  ]}  
          name='schedule'
          value={profile.schedule}
          onChange={onChangeSchedule} 
          error={errorschedule}
      />

      {schedule === 'online'|| profile.schedule === 'online'?
        <Form.Input 
          required
          label={productType+' schedule url'}
          placeholder='http://mysite.com' 
          defaultValue={profile.scheduleonline}
          name='scheduleonline'
          onChange={onChangeInput} 
          error={errorscheduleonline}
        />
        :<></>
      }

    
      {schedule === 'manual' || profile.schedule === 'manual' ?
          <div>
            {errorschedulemanual?
              <Message
                error
                icon='warning'
                content='Events are empty!'
              />            
              :<></>
            }

            <Button type='button' onClick={addEvent}> Add {productType}</Button>

            {myevents && myevents.map((el, index) => (
              <Form.Group key={index} style={{marginTop:'1em'}} >
                    <input
                      name='name'
                      required
                      placeholder='Name & Description'
                      value={el.name}
                      onChange={e => updateEvent(index, e)}
                    />
                  <Button type='button' basic  onClick={() => removeEvent(index)} icon > <Icon name='remove' color={'red'} /></Button>
              </Form.Group>    
            ))}
          </div>
        :<></>
        }         
  </Segment>      



    ) 
  };

export default SegmentProduct;