import axios from "axios";
import {config} from '../Config'



function register(userid, password, confpassword, token){

    return instance.post('/signup', { userid, password, confpassword, token } )
}




function login(username, password){

    return instance.post('/signin', {
         username, password
    })
    .then(response => {
        if(response.data.accessToken){
            localStorage.setItem("user", JSON.stringify(response.data))
        }

        return response.data
    })
}


function forgot(username){

    return instance.post('/token', { username })
}



function logout(){
    localStorage.removeItem("user")
}


function getCurrentUser(){
    return JSON.parse(localStorage.getItem("user"))
}


const instance = axios.create({
    baseURL: config.url.API_BASE_URL 
})



export const authService = {
    register, 
    login,
    logout,
    forgot,
    getCurrentUser
}