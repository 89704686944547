import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { useParams } from "react-router";
import ContractorCard from "./ContractorCard";
import CompanyCard from "./CompanyCard";
import TalentInfluencerCard from "./TalentInfluencerCard";
import TalentEgamerCard from "./TalentEgamerCard";
import TalentAthleteCard from "./TalentAthleteCard";
import TalentActorCard from "./TalentActorCard";
import TalentMusicCard from "./TalentMusicCard";
import OrgSportCard from "./OrgSportCard";
import OrgCultureCard from "./OrgCultureCard";
import OrgMediaCard from "./OrgMediaCard";





const Customer = () => {
  console.log('RENDER Customer')

  const {id} = useParams()
  const [customer, setCustomer] = useState()




  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      userService.getCustomer(id).then((response) => setCustomer(response.data))
    }
  }, []);

  
  console.log('customer', customer)

  return customer?  (
    <div style={{ marginLeft: '5em', marginRight: '5em', marginBottom:'5em' }}>

    {customer.customertype.toUpperCase() === 'COMPANY' ?
      <CompanyCard customer={customer} />
      :<></>
    }

    {customer.customertype.toUpperCase() === 'CONTRACTOR' ?
      <ContractorCard customer={customer} />
      :<></>
    }    

    {customer.customertype.toUpperCase() === 'OrganisationSport'.toUpperCase() ?
      <OrgSportCard customer={customer} />
      :<></>
    }


    {customer.customertype.toUpperCase() === 'OrganisationCulture'.toUpperCase() ?
      <OrgCultureCard customer={customer} />
      :<></>
    }    


    {customer.customertype.toUpperCase() === 'OrganisationMediaMagazine'.toUpperCase() ||
    customer.customertype.toUpperCase() === 'OrganisationMediaNewspaper'.toUpperCase() ||
    customer.customertype.toUpperCase() === 'OrganisationMediaRadio'.toUpperCase() ||
    customer.customertype.toUpperCase() === 'OrganisationMediaTV'.toUpperCase() ||
    customer.customertype.toUpperCase() === 'OrganisationMediaWebsite'.toUpperCase() ?
      <OrgMediaCard customer={customer} />
      :<></>
    }        


    {customer.customertype.toUpperCase() === 'TalentInfluencer'.toUpperCase() ?
      <TalentInfluencerCard customer={customer} />
      :<></>
    }    


    {customer.customertype.toUpperCase() === 'TalentEgamer'.toUpperCase() ?
      <TalentEgamerCard customer={customer} />
      :<></>
    }        


    {customer.customertype.toUpperCase() === 'TalentAthlete'.toUpperCase() ?
      <TalentAthleteCard customer={customer} />
      :<></>
    }            


    {customer.customertype.toUpperCase() === 'TalentActor'.toUpperCase() ?
      <TalentActorCard customer={customer} />
      :<></>
    }                


    {customer.customertype.toUpperCase() === 'TalentMusic'.toUpperCase() ?
      <TalentMusicCard customer={customer} />
      :<></>
    }                    





      
    </div>
  ) : 
  <Dimmer active inverted >
  <Loader inverted>Loading</Loader>
  </Dimmer>;
};

export default Customer;