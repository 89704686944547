import {  Table, Button, Icon, Container, Message } from "semantic-ui-react";
import {  NavLink } from 'react-router-dom'
import {  useHistory } from 'react-router-dom'
import ContractorNeedHeadRow from "./ContractorNeedHeadRow";


const ContractorNeedsTable = (props) => {
    console.log('ContractorNeedsTable')

    const myneeds = props.myneeds
    const id = props.id

    const newneeds = myneeds.publish ? myneeds.publish : myneeds
    const packagename = props.packagename

    const packageexpired=props.packageexpired


    const navigateto = (nid) => {
      //console.log(nid) 
      history.push(`/packages/${id}/mypublish/${nid}`)
    }


    const history = useHistory()

    return newneeds.length > 0 ? (

      <div style={{marginLeft:'2em', marginRight:'2em', marginBottom:'3em'}}>
      
        <h3>My published needs for <b>{myneeds.packagename?myneeds.packagename:packagename}</b></h3>

        <Table selectable>
              <Table.Header>
                <ContractorNeedHeadRow />
              </Table.Header>
    
              <Table.Body >
                {newneeds.map(n => { 
                  return (
                <Table.Row style={{cursor:'pointer'}}>
                <Table.Cell  >{n.creationDate}</Table.Cell>
                <Table.Cell>{n.country}</Table.Cell>                            
                <Table.Cell>{n.region==null?'All regions':n.region}</Table.Cell>
                <Table.Cell>{n.service}</Table.Cell>
                <Table.Cell>{n.minbudget.toLocaleString('en-US')}</Table.Cell>
                <Table.Cell>{n.maxbudget.toLocaleString('en-US')}</Table.Cell>
                <Table.Cell>{n.from}</Table.Cell>              
                <Table.Cell>{n.to}</Table.Cell>
                <Table.Cell style={ {color:n.active?'green':'red'}}>{n.active?'ACTIVE':'DISABLED'}</Table.Cell>              

                <Table.Cell>  
                  <Button disabled={packageexpired} onClick={()=> navigateto(n.id)} basic color='black'>View</Button> &nbsp; &nbsp;
                  
                  <Button disabled={packageexpired} animated basic color='black'>
                    <Button.Content visible>Edit</Button.Content>
                    <Button.Content as={NavLink} to={`/packages/${id}/mypublish/${n.id}/edit`} hidden>
                      <Icon name='edit' />
                    </Button.Content>
                  </Button>                
                </Table.Cell>
              </Table.Row>              
              )
                } )}
                </Table.Body>
           </Table>            

        </div>        
    ):
    <Container>
      <Message
        warning
        icon='warning'
        header='No needs found attached to this package'
        // content={'Form contains errors'}
      />
   </Container>
}

export default ContractorNeedsTable;