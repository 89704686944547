import  { useState} from "react";
import {userService} from "../../../services/user.service";
import {  Form, Button, Grid, Image } from "semantic-ui-react";
import {config} from '../../../Config'



const ProfileFacilityPhotoForm = (props) => {
  console.log('RENDER ProfileFacilityPhotoForm')

  const profile = props.profile;
  const dispatch = props.dispatch;

  const errorfacilityphoto = props.errorfacilityphoto
  const setErrorfacilityphoto = props.setErrorfacilityphoto

  const [logo, setLogo] = useState()
  const [selectedFile, setSelectedfile] = useState()


  const onChangeFile = (event, data) => {
    setSelectedfile(event.target.files[0])
  }


  const onUploadFile = (event, data) => {
    const formData = new FormData();

    formData.append(
      "myLogoFile",
      selectedFile,
      selectedFile.name
    );    

    userService.uploadFacilityFile(formData).then(() => {
        document.getElementById('uploadphotoid').value = null;
        setSelectedfile(null)
        setLogo(Date.now())
        dispatch({type:"UPDATE_STATE", name: 'facilityphoto', value: selectedFile.name});
        setErrorfacilityphoto(undefined)
    })
  }


  
  return (
    
     <Grid columns='equal' style={{ paddingBottom: '1em', marginTop:'1em' }}>
        {profile.facilityphoto || logo ?
          <Grid.Column width={2}>
            <Image src={ `${config.url.API_BASE_URL}/customers/${profile.customerid}/image/facility?hash=${logo}`} size='tiny' />  
          </Grid.Column>:<></>
        } 

        <Grid.Column>
          <Form.Input  
            error={errorfacilityphoto}
            type='file'
            id='uploadphotoid'       
            onChange={onChangeFile}
          />
        </Grid.Column>
      

        <Grid.Column>
         <Button type='button' disabled={!selectedFile} onClick={onUploadFile}>Upload photo</Button>
        </Grid.Column>
      </Grid>

    ) 
  };

export default ProfileFacilityPhotoForm;