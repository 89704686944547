
import { Segment, Label, Form } from "semantic-ui-react";
import {DateUtils} from "../../../services/Utils";

import ProfileFacilityPhotoForm from "./FacilityPhotoForm";



const SegmentFacility = (props) => {
  console.log('RENDER SegmentFacility')

  const profile = props.profile
  const user = props.user

  const dispatch = props.dispatch;
  

  const errorfacilityname = props.errorfacilityname
  const setErrorfacilityname = props.setErrorfacilityname

  const errorfacilitycapacity = props.errorfacilitycapacity
  const setErrorfacilitycapacity = props.setErrorfacilitycapacity

  const errorfacilityholders = props.errorfacilityholders
  const setErrorfacilityholders = props.setErrorfacilityholders  

  const errorfacilityphoto = props.errorfacilityphoto
  const setErrorfacilityphoto = props.setErrorfacilityphoto


  const onChangeInput = (event, data) => {

    let regex = new RegExp("^[-={}\\[\\]`~;:\\\\|<>/\"a-zA-Z0-9?$@$!%*?&#(),'^-_. +]+$");

    if(data.name === 'facilityname' ){
      if(data.value.length > 40){
        setErrorfacilityname({content: 'Name is more than 100 characters long', pointing: 'below'})
        return
      } 

      if(data.value.length === 0){
        setErrorfacilityname({content: 'Name cannot be empty', pointing: 'below'})
        return
      }

      if(data.value.length > 0 && !regex.test(data.value)){ 
        setErrorfacilityname({content: 'English only', pointing: 'below'})
        return
      }      

      setErrorfacilityname(undefined)
    }


    
    if(data.name === 'facilitycapacity' ){

      if(data.value.length === 0){
        setErrorfacilitycapacity({content: 'Capacity cannot be empty', pointing: 'below'})
        return
      }


      if(!DateUtils.isValidNumber(data.value)){
        setErrorfacilitycapacity({content: 'Must be number', pointing: 'below'})
        return
      }      


      setErrorfacilitycapacity(undefined)
    }
    

    if(data.name === 'facilityholders' ){

      if(data.value.length === 0){
        setErrorfacilityholders({content: 'Holders cannot be empty', pointing: 'below'})
        return
      }

      if(!DateUtils.isValidNumber(data.value)){
        setErrorfacilityholders({content: 'Must be number', pointing: 'below'})
        return
      }            

      setErrorfacilityholders(undefined)
    }
    
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }



  return   (
    <Segment raised>
      <Label as='a' color='yellow' ribbon>
        Facility
      </Label>                

      <Form.Input className='required'
        fluid   
        label='Name'
        labelPosition='left'
        placeholder='Facility name' 
        defaultValue={profile.facilityname}
        name='facilityname'
        error={ errorfacilityname}
        onChange={onChangeInput} 
      />


      <Form.Input className='required'
        fluid   
        icon='user'
        iconPosition='left'
        label='Capacity'
        labelPosition='left'
        placeholder='Capacity' 
        defaultValue={profile.facilitycapacity}
        name='facilitycapacity'
        error={ errorfacilitycapacity}
        onChange={onChangeInput} 
      />        

      {user && user.customerparenttype === "CULTURE" ?

      <Form.Input className='required'
        fluid   
        icon='user'
        iconPosition='left'
        label='Average ticket holders'
        labelPosition='left'
        placeholder='Average ticket holders' 
        defaultValue={profile.facilityholders}
        name='facilityholders'
        error={ errorfacilityholders}
        onChange={onChangeInput} 
      />
      :<></>}


    <ProfileFacilityPhotoForm 
      profile={profile}
      dispatch={dispatch}

      errorfacilityphoto={errorfacilityphoto}
      setErrorfacilityphoto={setErrorfacilityphoto}

    />      

  </Segment>


    ) 
  };

export default SegmentFacility;