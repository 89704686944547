import {authService} from "../../../services/auth.service";
import {userService} from "../../../services/user.service";
import  { useState, useEffect } from "react";
import { useParams } from "react-router";
import ContractorNeedDetails from './ContractorNeedDetails'
import OrgNeedsDetails from "./OrgNeedsDetails";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import ContractorOfferDetails from "./ContractorOfferDetails";
import OrgOfferDetails from "./OrgOfferDetails";




const PublishDetails = () => {
  console.log('RENDER PublishDetails')

  const {pid, nid} = useParams()

  
  const [publish, setPublish] = useState()




  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      userService.getMyNeed(nid).then((response) => setPublish(response.data))
    }
  }, [nid]);

  
  // console.log('publish', publish)
  return publish?  (

    <div>

        {publish.type === 'ContractorNeed' ?
          <ContractorNeedDetails publish={publish} pid={pid} nid={nid} />
          :<></>}

        {publish.type === 'OrganisationNeed' || publish.type === 'TalentNeed' ?
          <OrgNeedsDetails publish={publish} pid={pid} nid={nid} />
          :<></>}

        {publish.type === 'ContractorOffer' ?
          <ContractorOfferDetails publish={publish} pid={pid} nid={nid} />
          :<></>}

        {publish.type === 'OrgOffer' || publish.type === 'TalentOffer' ?
          < OrgOfferDetails publish={publish} pid={pid} nid={nid} />
          :<></>}      
      
    </div>
  ) : 
<Container>
  <Dimmer active inverted>
    <Loader inverted>Loading</Loader>
  </Dimmer>                
</Container>

};

export default PublishDetails;