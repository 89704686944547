import { useState } from "react";
import { Form, Button, Grid, Header, Image, Segment, Message } from "semantic-ui-react";
import {authService}  from "../../services/auth.service";


const Forgot = () => {
  console.log('RENDER Forgot')
    
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [infomessage, setInfomessage] = useState(false);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    authService.forgot(username).then(() => {
      setLoading(false);
      setInfomessage(true);
      },
      error => {
        // console.log(error.response)
        const response = error.response

        if(!response){
          setErrormessage("Server does not respond");
        }

        if(error.response.status === 500){
          setErrormessage("Unknown server error");
        } else {
          setErrormessage("Invalid username or password");
        }

        setLoading(false);

      }
    );
  };
  

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        
        <Header as='h2' color='blue' textAlign='center'>
          <Image src='/images/logo.png' /> Forgot Password
        </Header>

        <Form 
          loading={loading}
          size='large' 
          onSubmit={handleSubmit}
          error={errormessage.length > 0}
          warning={infomessage}
          >
          { !infomessage && <Message
                content="Please enter your email and click submit."
              />
          }

          <Message warning
                content="Thank you! An Email has been sent to your address"
              />           
                     
          <Segment stacked>
            
            <Form.Input 
              fluid icon='user' 
              iconPosition='left' 
              placeholder='E-mail address' 
              onChange={onChangeUsername} 
            />

            <Button color='blue' fluid size='large'>
              Submit
            </Button>
          </Segment>
        </Form>


      </Grid.Column>
    </Grid>
  );
};

export default Forgot;