import { Table } from "semantic-ui-react";

const ContractorOfferHeadRow = (props) => {
    console.log('RENDER ContractorOfferHeadRow')

    return (
        <Table.Row>
                  <Table.HeaderCell>Created on</Table.HeaderCell>
                  <Table.HeaderCell>Country</Table.HeaderCell>
                  <Table.HeaderCell>Region</Table.HeaderCell>
                  <Table.HeaderCell>Service</Table.HeaderCell>
                  <Table.HeaderCell>Cost</Table.HeaderCell>
                  <Table.HeaderCell>Period From</Table.HeaderCell>
                  <Table.HeaderCell>Period To</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
      </Table.Row>        
    )
}


export default ContractorOfferHeadRow;