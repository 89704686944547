import { useState } from "react";
import { Segment, Label, Form, Radio } from "semantic-ui-react";
import {DateUtils} from "../../../services/Utils";



const SegmentAudience = (props) => {
  console.log('RENDER SegmentAudience')

  const profile = props.profile
  const user = props.user
  const dispatch = props.dispatch;

  const erroravgticketholders = props.erroravgticketholders
  const setErroravgticketholders = props.setErroravgticketholders

  const erroravgticketholderschamp = props.erroravgticketholderschamp
  const setErroravgticketholderschamp = props.setErroravgticketholderschamp

  const erroravgticketholderscup = props.erroravgticketholderscup
  const setErroravgticketholderscup = props.setErroravgticketholderscup

  const erroravgticketholdersother = props.erroravgticketholdersother
  const setErroravgticketholdersother = props.setErroravgticketholdersother

  const errorseasonticketholders = props.errorseasonticketholders
  const setErrorseasonticketholders = props.setErrorseasonticketholders

  const erroraudiencemembers = props.erroraudiencemembers
  const setErroraudiencemembers = props.setErroraudiencemembers

  const errortvbroadcasting = props.errortvbroadcasting
  const setErrortvbroadcasting = props.setErrortvbroadcasting

  const errorradiobroadcasting = props.errorradiobroadcasting
  const setErrorradiobroadcasting = props.setErrorradiobroadcasting

  const errornewscoverage = props.errornewscoverage
  const setErrornewscoverage = props.setErrornewscoverage
  
  const errormagazinescoverage = props.errormagazinescoverage
  const setErrormagazinescoverage = props.setErrormagazinescoverage
  
  const errorwebcoverage = props.errorwebcoverage
  const setErrorwebcoverage = props.setErrorwebcoverage  

  const errormembersnumber = props.errormembersnumber
  const setErrormembersnumber = props.setErrormembersnumber

  const errortvbroadcastingnumber=props.errortvbroadcastingnumber
  const setErrortvbroadcastingnumber=props.setErrortvbroadcastingnumber

  const [member, setMember] = useState()
  const [tvbroadcasting, setTvbroadcasting] = useState()
  const [radiobroadcasting, setRadiobroadcasting] = useState()
  const [newspaperscoverage, setNewspaperscoverage] = useState()
  const [magazinescoverage, setMagazinescoverage] = useState()
  const [webcoverage, setWebcoverage] = useState()

  const onChangeMember = (event, data) => {
    setMember(data.value)
    setErroraudiencemembers(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }


  const onChangeTVBroadcasting = (event, data) => {
    setTvbroadcasting(data.value)
    setErrortvbroadcasting(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }


  const onChangeRadioBroadcasting = (event, data) => {
    setRadiobroadcasting(data.value)
    setErrorradiobroadcasting(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});    
  }


  const onChangeNewspapersCoverage = (event, data) => {
    setNewspaperscoverage(data.value)
    setErrornewscoverage(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});        
  }


  const onChangeMagazinesCoverage = (event, data) => {
    setMagazinescoverage(data.value)
    setErrormagazinescoverage(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});            
  }


  const onChangeOnlineCoverage = (event, data) => {
    setWebcoverage(data.value)
    setErrorwebcoverage(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});                
  }


  const onChangeInput = (event, data) => {

    if(data.name === 'avgticketholders' ){
      if(data.value.length === 0){
        setErroravgticketholders({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      if(!DateUtils.isValidNumber( data.value)){
        setErroravgticketholders({content: 'Only digits are allowed', pointing: 'below'})
        return
      }             

      setErroravgticketholders(undefined)
    }

    if(data.name === 'avgticketholderschamp' ){
      if(data.value.length === 0){
        setErroravgticketholderschamp({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      if(!DateUtils.isValidNumber(  data.value)){
        setErroravgticketholderschamp({content: 'Only digits are allowed', pointing: 'below'})
        return
      }             

      setErroravgticketholderschamp(undefined)
    }
    
    if(data.name === 'avgticketholderscup' ){
      if(data.value.length === 0){
        setErroravgticketholderscup({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      if(!DateUtils.isValidNumber(  data.value)){
        setErroravgticketholderscup({content: 'Only digits are allowed', pointing: 'below'})
        return
      }             

      setErroravgticketholderscup(undefined)
    }
    
    if(data.name === 'avgticketholdersother' ){
      if(data.value.length === 0){
        setErroravgticketholdersother({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      if(!DateUtils.isValidNumber(  data.value)){
        setErroravgticketholdersother({content: 'Only digits are allowed', pointing: 'below'})
        return
      }             

      setErroravgticketholdersother(undefined)
    }
    
    if(data.name === 'seasonticketholders' ){
      if(data.value.length === 0){
        setErrorseasonticketholders({content: 'Number cannot be empty', pointing: 'below'})
        return
      }

      if(!DateUtils.isValidNumber(  data.value)){
        setErrorseasonticketholders({content: 'Only digits are allowed', pointing: 'below'})
        return
      }             

      setErrorseasonticketholders(undefined)
    }    


    if(data.name === 'membersnumber' ){
      if(!DateUtils.isValidNumber(  data.value)){
        setErrormembersnumber({content: 'Only digits are allowed', pointing: 'below'})
        return
      }             

      setErrormembersnumber(undefined)
    }        

    

    if(data.name === 'tvevents' ){
      if(!DateUtils.isValidNumber(  data.value)){
        setErrortvbroadcastingnumber  ({content: 'Only digits are allowed', pointing: 'below'})
        return
      }             

      setErrortvbroadcastingnumber(undefined)
    }            
    


    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }



  return   (
  <Segment raised>
      <Label as='a' color='violet' ribbon>
          Audience
      </Label>                

      {user && user.customerparenttype === 'SPORT' ?
      <div>
      <Form.Input 
            fluid   
            className='required'
            icon='user'
            iconPosition='left'
            label='Average ticket holders - General'
            labelPosition='left'
            placeholder='Average ticket holders' 
            defaultValue={profile.avgticketholders}
            name='avgticketholders'
            error={ erroravgticketholders}
            onChange={onChangeInput} 
        />        


          <Form.Input 
            fluid   
            className='required'
            icon='user'
            iconPosition='left'
            label='Average ticket holders - Championship'
            labelPosition='left'
            placeholder='Average ticket holders' 
            defaultValue={profile.avgticketholderschamp}
            name='avgticketholderschamp'
            error={ erroravgticketholderschamp}
            onChange={onChangeInput} 
        />        


          <Form.Input 
            fluid   
            className='required'
            icon='user'
            iconPosition='left'
            label='Average ticket holders - Cup'
            labelPosition='left'
            placeholder='Average ticket holders' 
            defaultValue={profile.avgticketholderscup}
            name='avgticketholderscup'
            error={ erroravgticketholderscup}
            onChange={onChangeInput} 
        />        


          <Form.Input 
            fluid   
            className='required'
            icon='user'
            iconPosition='left'
            label='Average ticket holders Other(International etc)'
            labelPosition='left'
            placeholder='Average ticket holders' 
            defaultValue={profile.avgticketholdersother}
            name='avgticketholdersother'
            error={ erroravgticketholdersother}
            onChange={onChangeInput} 
        />     

          <Form.Input 
            fluid   
            className='required'
            icon='user'
            iconPosition='left'
            label='Season ticket holders'
            labelPosition='left'
            placeholder='Season ticket holders' 
            defaultValue={profile.seasonticketholders}
            name='seasonticketholders'
            error={ errorseasonticketholders}
            onChange={onChangeInput} 
        />             
        </div>   
        :<></>}


      {user && user.customerparenttype === 'CULTURE' ?
      <div>
      <h5>Members</h5>
       <Form.Group inline className='required'>
          <Form.Field 
            control={Radio}
            label='Yes'
            value='yes'
            checked={member === 'yes' || profile.members === 'yes'}
            onChange={onChangeMember}
            name='members'
            error={erroraudiencemembers}
          />

          <Form.Field
            control={Radio}
            label='No'
            value='no'
            checked={member === 'no' || profile.members === 'no'}
            onChange={onChangeMember}
            name='members'
          />
       </Form.Group>
               
        {(member === 'yes' || profile.members === 'yes') ?
            <Form.Input 
                fluid   
                label='Number of members'
                labelPosition='left'
                icon='user'
                iconPosition='left'
                width={5}
                defaultValue={profile.membersnumber}
                name='membersnumber'
                error={ errormembersnumber}
                onChange={onChangeInput} 
            />
          :<></>
          }
        </div>
         :<></>}

      <h5>TV Broadcasting</h5>
      <Form.Group inline className='required'>
          <Form.Field
            control={Radio}
            label='Yes'
            value='yes'
            checked={tvbroadcasting === 'yes' || profile.tvbroadcasting === 'yes'}
            onChange={onChangeTVBroadcasting}
            error={errortvbroadcasting}
            name='tvbroadcasting'
          />

          <Form.Field
            control={Radio}
            label='No'
            value='no'
            checked={tvbroadcasting === 'no' || profile.tvbroadcasting === 'no'}
            onChange={onChangeTVBroadcasting}
            name='tvbroadcasting'
          />
      </Form.Group>

    {tvbroadcasting === 'yes' || profile.tvbroadcasting === 'yes'?
      <div>
          <Form.Input 
              fluid   
              required
              label='Number of televised events'
              labelPosition='left'
              icon='user'
              iconPosition='left'
              width={5}
              defaultValue={profile.tvevents}
              name='tvevents'
              onChange={onChangeInput} 
              error={errortvbroadcastingnumber}
          />

          <Form.TextArea 
            label='Ratings'
            defaultValue={profile.tvratings} 
            placeholder='Short description'
            name='tvratings'
            onChange={onChangeInput} 
          />

          <Form.TextArea 
            label='Audience'
            defaultValue={profile.tvaudience} 
            placeholder='Short description'
            name='tvaudience'
            onChange={onChangeInput} 
          />        


          <Form.TextArea 
            label='Markets (countries)'
            defaultValue={profile.tvmarkets} 
            placeholder='Countries'
            name='tvmarkets'
            onChange={onChangeInput} 
          />                
      </div>     
    :<></>
    }        


      <h5>Radio Broadcasting</h5>
      <Form.Group inline className='required'>
        <Form.Field
          control={Radio}
          label='Yes'
          value='yes'
          checked={radiobroadcasting === 'yes' || profile.radiobroadcasting === 'yes'}
          onChange={onChangeRadioBroadcasting}
          error={errorradiobroadcasting}
          name='radiobroadcasting'
        />

        <Form.Field
          control={Radio}
          label='No'
          value='no'
          checked={radiobroadcasting === 'no' || profile.radiobroadcasting === 'no'}
          onChange={onChangeRadioBroadcasting}
          name='radiobroadcasting'
        />
      </Form.Group>

    {radiobroadcasting === 'yes' || profile.radiobroadcasting === 'yes' ?
    <div>
    <Form.Input 
        fluid   
        required
        label='Number of radio broadcasted events'
        labelPosition='left'
        icon='user'
        iconPosition='left'
        width={5}
        defaultValue={profile.radioevents}
        name='radioevents'
        onChange={onChangeInput} 
    />

    <Form.TextArea 
      label='Ratings'
      defaultValue={profile.radioratings} 
      placeholder='Short description'
      name='radioratings'
      onChange={onChangeInput} 
    />

    <Form.TextArea 
      label='Audience'
      defaultValue={profile.radioaudience} 
      placeholder='Short description'
      name='radioaudience'
      onChange={onChangeInput} 
    />        


    <Form.TextArea 
      label='Markets (regions in the country)'
      defaultValue={profile.radiomarkets} 
      placeholder='Regions in the country'
      name='radiomarkets'
      onChange={onChangeInput} 
    />                

    </div>     
    :<></>
    }        




    <h5>Newspapers Coverage</h5>
    <Form.Group inline className='required'>

        <Form.Field
          control={Radio}
          label='Yes'
          value='yes'
          checked={newspaperscoverage === 'yes' || profile.newspaperscoverage === 'yes'}
          onChange={onChangeNewspapersCoverage}
          name='newspaperscoverage'
          error={ errornewscoverage}
        />

        <Form.Field
          control={Radio}
          label='No'
          value='no'
          checked={newspaperscoverage === 'no'|| profile.newspaperscoverage === 'no'}
          onChange={onChangeNewspapersCoverage}
          name='newspaperscoverage'
        />
    </Form.Group>

      {newspaperscoverage === 'yes' || profile.newspaperscoverage === 'yes'?
      <div>
        <Form.Input 
            fluid   
            label='Number of newspapers'
            labelPosition='left'
            icon='user'
            iconPosition='left'
            width={5}
            defaultValue={profile.newspapers}
            name='newspapers'
            onChange={onChangeInput} 
        />


        <Form.Input 
          fluid   
          label='Number of articles'
          labelPosition='left'
          icon='user'
          iconPosition='left'
          width={5}
          defaultValue={profile.newsarticles}
          name='newsarticles'
          onChange={onChangeInput} 
        />        

        <Form.TextArea 
          label='Audience'
          defaultValue={profile.newshouseholds} 
          placeholder='Short description'
          name='newshouseholds'
          onChange={onChangeInput} 
        />

        <Form.TextArea 
          label='Markets (regions in the country)'
          defaultValue={profile.newsmarkets} 
          placeholder='Regions in the country'
          name='newsmarkets'
          onChange={onChangeInput} 
        />        

      </div>     
      :<></>
      }        


    <h5>Magazines Coverage</h5>
    <Form.Group inline  className='required'>

        <Form.Field
          control={Radio}
          label='Yes'
          value='yes'
          checked={magazinescoverage === 'yes'|| profile.magazinescoverage === 'yes'}
          onChange={onChangeMagazinesCoverage}
          name='magazinescoverage'
          error={ errormagazinescoverage}          
        />

        <Form.Field
          control={Radio}
          label='No'
          value='no'
          checked={magazinescoverage === 'no'|| profile.magazinescoverage === 'no'}
          onChange={onChangeMagazinesCoverage}
          name='magazinescoverage'
        />
      </Form.Group>

    {magazinescoverage === 'yes'|| profile.magazinescoverage === 'yes'?
    <div>
    <Form.Input 
        fluid   
        label='Number of magazines'
        labelPosition='left'
        icon='user'
        iconPosition='left'
        width={5}
        defaultValue={profile.magazinesnumber}
        name='magazinesnumber'
        onChange={onChangeInput} 
    />


      <Form.Input 
        fluid   
        label='Number of articles'
        labelPosition='left'
        icon='user'
        iconPosition='left'
        width={5}
        defaultValue={profile.magazinesarticles}
        name='magazinesarticles'
        onChange={onChangeInput} 
    />        

      <Form.TextArea 
        label='Audience'
        defaultValue={profile.magazinesaudience} 
        placeholder='Short description'
        name='magazinesaudience'
        onChange={onChangeInput} 
      />

      <Form.TextArea 
        label='Markets (regions in the country)'
        defaultValue={profile.magazinesmarkets} 
        placeholder='Regions in the country'
        name='magazinesmarkets'
        onChange={onChangeInput} 
      />        

    </div>     
    :<></>
    }        

    
    <h5>Digital Media Coverage</h5>
    <Form.Group inline className='required'>

        <Form.Field
          control={Radio}
          label='Yes'
          value='yes'
          checked={webcoverage === 'yes'|| profile.webcoverage === 'yes'}
          onChange={onChangeOnlineCoverage}
          name='webcoverage'
          error={ errorwebcoverage}                   
        />

        <Form.Field
          control={Radio}
          label='No'
          value='no'
          checked={webcoverage === 'no'|| profile.webcoverage === 'no'}
          onChange={onChangeOnlineCoverage}
          name='webcoverage'
        />
      </Form.Group>

    {webcoverage === 'yes'|| profile.webcoverage === 'yes'?
      <div>
        <Form.Input 
            fluid   
            label='Number of websites'
            labelPosition='left'
            icon='user'
            iconPosition='left'
            width={5}
            defaultValue={profile.webnumber}
            name='webnumber'
            onChange={onChangeInput} 
          />

        <Form.Input 
          fluid   
          label='Number of articles'
          labelPosition='left'
          icon='user'
          iconPosition='left'
          width={5}
          defaultValue={profile.webarticles}
          name='webarticles'
          onChange={onChangeInput} 
        />        

        <Form.TextArea 
          label='Analytics (visitors, views, etc)'
          defaultValue={profile.webanalytics} 
          placeholder='visitors, views, etc'
          name='webanalytics'
          onChange={onChangeInput} 
        />

        <Form.TextArea 
          label='Markets (countries)'
          defaultValue={profile.webmarkets} 
          placeholder='countries'
          name='webmarkets'
          onChange={onChangeInput} 
        />        

      </div>     
    :<></>
    }        

  </Segment>


    ) 
  };

export default SegmentAudience;