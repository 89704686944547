import { useState, useEffect } from "react";
import {authService} from "../../../services/auth.service";
import ContractorNeedHeadRow from "./ContractorNeedHeadRow";
import { config } from "../../../Config";
import { Table, Button, Loader, Dimmer, Segment, Header, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {userService} from "../../../services/user.service";


const ContractorNeedDetails = (props) => {
  console.log('RENDER ContractorNeedDetails')
  // console.log(props)

  const publish = props.publish

  const pid = props.pid
  const nid = props.nid

  // console.log('action', action)
  const [pname, setPname] = useState()
  const [matches, setMatches] = useState()  
  const [loader, setLoader] = useState(false)



  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      userService.getMyPackage(pid).then((response) =>   {
      setPname (response.data.name)
      // console.log(response.data)
      }
      )
    }
  }, [pid]);


  const onFind = (e) => {
    setLoader(true)
    userService.getMyMatches(nid).then((response) => {
      setMatches(response.data)
      setLoader(false)
    })
  };


  console.log('publish', matches)


  return publish &&
    <div style={{marginLeft:'2em', marginRight:'2em'}}>

      <h3>My published need for <b>{pname}</b></h3>

        <Table>
            <Table.Header>
              <ContractorNeedHeadRow />
            </Table.Header>

            <Table.Body>
            <Table.Row>
            <Table.Cell>{publish.creationDate}</Table.Cell>
            <Table.Cell>{publish.country}</Table.Cell>                            
            <Table.Cell>{publish.region?publish.region:'All regions'}</Table.Cell>

            <Table.Cell>{publish.service}</Table.Cell>
            <Table.Cell>{publish.minbudget.toLocaleString('en-US')}</Table.Cell>
            <Table.Cell>{publish.maxbudget.toLocaleString('en-US')}</Table.Cell>
            <Table.Cell>{publish.from}</Table.Cell>              
            <Table.Cell>{publish.to}</Table.Cell>              
            <Table.Cell style={{color:publish.active?'green':'red'}}> {publish.active?'ACTIVE':'DISABLED'}</Table.Cell>              
            <Table.Cell> 
                <Button onClick={onFind} disabled={!publish.active}> Find Offers </Button>  
            </Table.Cell>
        </Table.Row>

            </Table.Body>
        </Table>
          


        {matches || loader ? (
            <Segment compact >
                    <h4>Found {matches && matches.length} matches</h4>
                    <Table basic='very' celled collapsing>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Country</Table.HeaderCell>
                        <Table.HeaderCell>Region</Table.HeaderCell>                    
                        <Table.HeaderCell>From</Table.HeaderCell>          
                        <Table.HeaderCell>To</Table.HeaderCell>          
                        <Table.HeaderCell>Cost</Table.HeaderCell>          
                        <Table.HeaderCell>Description</Table.HeaderCell>          
                        </Table.Row>
                    </Table.Header>
                
                    <Table.Body>
                        {matches && matches.length === 0 ? 
                    <Table.Row >
                        <Table.Cell>Sorry no matches found</Table.Cell>
                    </Table.Row>
                    :
                    <></>
                    }

                    {loader ?
                    <Table.Row >
                        <Table.Cell>
                            <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                            </Dimmer>              
                        </Table.Cell>
                    </Table.Row>      
                    : <></>
                    }

                        {matches &&  matches.map(m => 
                        <Table.Row>
                        <Table.Cell>
                            <Header as='h4' image>
                            
                            {m.logo &&  
                            <Image src={ `${config.url.API_BASE_URL}/customers/${m.customerid}/image/logo`} rounded size='mini' />}


                            <Header.Content>
                            <Link to={`/customers/${m.customerid}`}>{m.name}</Link>

                            </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{m.country}</Table.Cell>                    
                        <Table.Cell>{m.region}</Table.Cell>
                        <Table.Cell>{m.from}</Table.Cell>          
                        <Table.Cell>{m.to}</Table.Cell>          
                        <Table.Cell>{m.cost}</Table.Cell>
                        <Table.Cell width={5}>{m.description}</Table.Cell>                                  
                        {/* <Table.Cell> {m.favorite? <Icon color='red' name='heart outline' /> : <Icon name='heart outline' />}  </Table.Cell> */}
                        </Table.Row>
                        )}
                        </Table.Body>
                        </Table>  
                </Segment>
    )
    :(<></>)}          
    </div>

  
};

export default ContractorNeedDetails;