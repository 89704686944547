import  { useState} from "react";
import {userService} from "../../../services/user.service";
import {  Form, Button, Grid, Image } from "semantic-ui-react";
import {config} from '../../../Config'



const ProfileLogoForm = (props) => {
  console.log('RENDER ProfileLogoForm')

  const profile = props.profile;
  const dispatch = props.dispatch;

  const errorlogo = props.errorlogo
  const setErrorlogo = props.setErrorlogo

  const photo = props.photo==null?'logo':'photo'

  const [logo, setLogo] = useState()
  const [selectedFile, setSelectedfile] = useState()


  const onChangeFile = (event, data) => {
    setSelectedfile(event.target.files[0])
  }


  const onUploadFile = (event, data) => {
    const formData = new FormData();

    formData.append(
      "myLogoFile",
      selectedFile,
      selectedFile.name
    );    

    userService.uploadLogoFile(formData).then(() => {
        document.getElementById('uploadinputid').value = null;
        setSelectedfile(null)
        setLogo(Date.now())
        dispatch({type:"UPDATE_STATE", name: 'logo', value: selectedFile.name});
        setErrorlogo(undefined)
    })
  }


  
  return (
    
     <Grid columns='equal' style={{ paddingBottom: '1em', marginTop:'1em' }}>
        {profile.logo || logo ? (
          <Grid.Column width={2}>
            <Image src={ `${config.url.API_BASE_URL}/customers/${profile.customerid}/image/logo?hash=${logo} `} size='tiny' />  
          </Grid.Column>
          ) : <></> 
        } 

        <Grid.Column>
          <Form.Input  
            error={errorlogo}
            type='file'
            id='uploadinputid'       
            onChange={onChangeFile}
          />
        </Grid.Column>
      

        <Grid.Column>
         <Button type='button' disabled={!selectedFile} onClick={onUploadFile}>Upload {photo}</Button>
        </Grid.Column>
      </Grid>

    ) 
  };

export default ProfileLogoForm;