import {authService} from "../../services/auth.service";
import  { useEffect } from "react";
import { useHistory } from "react-router";
import { Container } from "semantic-ui-react";
import ProfileCompanyForm from "./ProfileCompanyForm";
import ProfileTalentForm from "./ProfileTalentForm";
import ProfileContractorForm from "./ProfileContractorForm";
import ProfileOrgCultureForm from "./ProfileOrgCultureForm";
import ProfileOrgMediaForm from "./ProfileOrgMediaForm ";
import ProfileOrgSportForm from "./ProfileOrgSportForm";



const ProfileForm = () => {
  console.log('RENDER ProfileForm')


  const user = authService.getCurrentUser();
  const history = useHistory();

  console.log(user)

   useEffect(() => {

    if (!user) {
      history.push("/login");
      window.location.reload();
    } 
  }, []);

  

  return   (
    <Container style={{ paddingBottom: '5em' }} text>

      {user && user.customerparenttype === 'TALENT'?
        <ProfileTalentForm />:
        <></>
      }

      {user && user.customertype === 'COMPANY'?
        <ProfileCompanyForm/> :
        <></>
      }


      {user && user.customertype === 'CONTRACTOR'?
        <ProfileContractorForm/> :
        <></>
      }


      {user && user.customerparenttype === 'SPORT'?
        <ProfileOrgSportForm/> :
        <></>
      }

      {user && user.customerparenttype === 'MEDIA'?
        <ProfileOrgMediaForm /> :
        <></>
      }

      {user && user.customerparenttype === 'CULTURE'?
        <ProfileOrgCultureForm/> :
        <></>
      }




    </Container>
    ) 
  

  };

export default ProfileForm;