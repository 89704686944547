
import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect } from "react";
import {Message, Container, Card, Icon, Dimmer, Loader, Segment } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";






const MyPackages = () => {
  console.log('RENDER MyPackages')

  const [currentUser, setCurrentUser] = useState(undefined);
  const [packages, setPackages] = useState()
  const history = useHistory();


  useEffect(() => {
    const user = authService.getCurrentUser();
    setCurrentUser(user)

    if (user) {
      setCurrentUser(user);

      userService.getCustomerPackages().then((response) => {
        setPackages(response.data)

        // if(user.customertype=='COMPANY'){
        //   setNeeds(packages)
        // }


        // if(user.customertype=='CONTRACTOR' || user.customerparenttype=='TALENT' || user.customerparenttype=='CULTURE' || user.customerparenttype=='MEDIA' || user.customerparenttype=='SPORT'){
        //   setOffers (packages.filter(p => p.offerscount > 0))
        //   setNeeds(packages.filter(p => p.needscount > 0))
        // }                

      } )
      .catch(error => {
        // console.log('err',error)
        if(error.response == null || error.response.status === 401){
          authService.logout();
          history.push("/login");
          window.location.reload();
        } else {
              history.push("/error500");
        }
      })      
    } 
  }, []);



  return (
    <Container>

    {!packages?
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer> 
    :<></>} 

      {packages && packages.length === 0 ? 
      <div style={{width:'40em'}}>
                      <Message
                        warning
                        icon='warning'
                        header='No packages found'
                    />
                    </div>
        :<></>
      }

      {packages && packages.length > 0?
      <Segment style={{marginBottom:'2em'}} raised>

        <Card.Group>
        {packages.map(p => 
          <Card style={{width:'30em'}}>

            <Card.Content>
              <Card.Header>{p.name}</Card.Header>
              <Card.Meta>
                <span className='date'>Created on {p.creationDate}</span>
              </Card.Meta>
              <Card.Meta>
                <span className='date' style={p.expired?  {color:'red'}: {}}>Expire{p.expired?'d':'s'} on {p.expiryDate}</span>              
              </Card.Meta>

              <Card.Description>
                <span>Provides access to: <b> {p.target} </b></span> <br/>
                <span>Located in: {p.country} - {p.region}</span>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>

            {p.needscount > 0 && <NavLink to={`/packages/${p.id}/publish?type=need&src=${currentUser.customerparenttype?currentUser.customerparenttype:currentUser.customertype}&dst=${p.target}`}> <Icon name='bullhorn' /> {p.needscount} Need(s) </NavLink>}
            {p.needscount>0  && p.offerscount>0 && <>&nbsp; &nbsp; &nbsp;</>}
            {p.offerscount>0 && <NavLink to={`/packages/${p.id}/publish?type=offer&src=${currentUser.customerparenttype?currentUser.customerparenttype:currentUser.customertype}&dst=${p.target}`}> <Icon name='bullhorn' /> {p.offerscount} Offer(s) </NavLink>}
            

            </Card.Content>
          </Card>
        )}          
        </Card.Group>

      </Segment>
      :<></>}


      

    </Container>
  )   

};

export default MyPackages;