
import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect } from "react";
import { Container, Loader, Dimmer } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";


import OrganisationNeedForm from "./OrganisationNeedForm";
import OrganisationOfferForm from "./OrganisationOfferForm";

import ContractorNeedForm from "./ContractorNeedForm";
import TalentNeedForm from "./TalentNeedForm";
import ContractorOfferForm from "./ContractorOfferForm";




const EditPublishForm = () => {
  console.log('RENDER EditPublishForm')
  
  const {pid, nid} = useParams()

  const [publish, setPublish] = useState()

  const history = useHistory();

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      userService.getMyNeed(nid).then((response) => setPublish(response.data) )
      .catch(error => {
        // console.log(error.response)
        if(error.response.status === 401){
          authService.logout();
          history.push("/login");
          window.location.reload();
        } 
      })            

    }
  }, []);

  
  // console.log('cus', publish)
  return   (
    <Container style={{ marginTop: '1em', marginBottom: '1em' }}  >

    {!publish &&
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>   
    }                 
    

    { publish &&  publish.type === 'ContractorNeed'  &&
        <ContractorNeedForm pid={pid} nid={nid} />
    }          


    {publish &&  publish.type === 'OrganisationNeed'  &&
        <OrganisationNeedForm pid={pid} nid={nid} />
    }    
    

    { publish  && publish.type === 'TalentNeed'  &&
        <TalentNeedForm pid={pid} nid={nid} />
    } 


    { publish  && publish.type === 'ContractorOffer' &&
        <ContractorOfferForm pid={pid} nid={nid} />
    }          


    { publish  && (publish.type === 'OrgOffer' || publish.type === 'TalentOffer') &&
        <OrganisationOfferForm pid={pid} nid={nid} />
    }              

    </Container>
  ) 

};

export default EditPublishForm;

