
import {authService} from '../../services/auth.service'
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Form, Button, Modal, Header, Icon, Message } from "semantic-ui-react";
import { useHistory } from 'react-router-dom'
import {DateUtils} from "../../services/Utils";


const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;
    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }
    default:
      return profile;
  }  
}



const ContractorNeedForm = (props) => {
  console.log('RENDER ContractorNeedForm')

  const initialState = {};

  const pid  = props.pid
  const nid = props.nid

  const history = useHistory();

  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [need, dispatch] = useReducer(formReducer, initialState);
  const [services, setServices] = useState()
  const [needsaved, setNeedsaved] = useState(false)
  const [status, setStatus] = useState()

  //errors
  const [errorservice, setErrorservice] = useState()
  const [errorminbudget, setErrorminbudget] = useState()
  const [errormaxbudget, setErrormaxbudget] = useState()
  const [errorfrom, setErrorfrom] = useState()
  const [errorto, setErrorto] = useState()
  const [publisherror, setPublisherror] = useState(false)



  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {

      if(nid){
          userService.getMyNeed(nid).then((response) => {
            dispatch({ type:FETCH_STATE, payload: response.data }) 

            setServices([{ key:'mykey', value:'mykey', text:response.data.service}])
            setStatus(response.data.active)
          })        

      } else {
        userService.getContractorServicesOptions().then((response) => setServices(response.data))
      }
    }
  }, []);


  const haserrors = () => errorservice || errorminbudget || errormaxbudget || errorfrom || errorto

  const notemptyError = {content: 'Cannot be empty', pointing: 'below'}
  const numberonly = {content: 'Numbers allowed only', pointing: 'below'}
  const notvaliddate = {content: 'Not valid date format', pointing: 'below'}
  const notvaliddateperiod = {content: 'Not valid date period', pointing: 'below'}
  const pastdate = {content: 'Date belongs to the past', pointing: 'below'}




  const handleSubmit = (e) => {
    // console.log('e', e)
    e.preventDefault();

    let errors = false

    
    if(need.service==null){
      setErrorservice(notemptyError)
      errors = true
    }

    //DEFAULT    
    if(need.minbudget==null){
      setErrorminbudget(notemptyError)
      errors = true
    }    

    let isnumbermin=true
    if(need.minbudget!=null && !DateUtils.isValidNumber( need.minbudget)){
      setErrorminbudget(numberonly)
      isnumbermin=false
      errors = true
    }        

    
    if(isnumbermin && need.minbudget!=null && need.maxbudget!=null && parseInt(need.minbudget, 10) > parseInt(need.maxbudget, 10)){
      setErrorminbudget({content: 'Cannot be greater than max budget', pointing: 'below'})
      errors = true
    }       


    if(need.maxbudget==null){
      setErrormaxbudget(notemptyError)
      errors = true
    }        


    let isnumbermax=true
    if(need.maxbudget!=null && !DateUtils.isValidNumber( need.maxbudget)){
      setErrormaxbudget({content: 'Numbers allowed only', pointing: 'below'})
      isnumbermax=false
      errors = true
    }        



    if(isnumbermin && isnumbermax && need.minbudget!=null && need.maxbudget!=null && parseInt(need.maxbudget, 10) < parseInt(need.minbudget, 10)){
      setErrormaxbudget({content: 'Cannot be less than min budget', pointing: 'below'})
      errors = true
    }             



    if(need.from==null){
      setErrorfrom(notemptyError)
      errors = true
    }        

    if(need.from!=null && !DateUtils.dateIsValid(need.from)){
      setErrorfrom(notvaliddate)
      errors = true
    }            
    
    if(need.to==null){
      setErrorto(notemptyError)
      errors = true
    }            

    if(need.to != null && !DateUtils.dateIsValid(need.to)){
      setErrorto(notvaliddate)
      errors = true
    }                


    if(need.to != null && need.from != null && DateUtils.dateIsValid(need.to) && DateUtils.dateIsValid(need.from) && DateUtils.getTimestamp(need.to) < DateUtils.getTimestamp(need.from)){
      setErrorto(notvaliddateperiod)
      setErrorfrom(notvaliddateperiod)      
      errors = true
    }


    if(DateUtils.dateIsPast  ( need.to) ){
      setErrorto(pastdate)
      errors = true
    }           


    if(errors){
      return
    }

    setLoader(true)
    setPublisherror(false)
    setNeedsaved(false)
    if(!nid){
      userService.createNeed(need, pid).then(()=> {
        setNeedsaved(true)

      })
      .catch(error => {
        // console.log(error.response)
        if(error.response.status === 409){
          setPublisherror('Need already exists for dates specified')
        }

        if(error.response.status === 500){
          setPublisherror('Server responded with unknown error')
        }        
      })
      .finally(() => {
        setLoader(false)
      })
    } else {
      userService.updateNeed(need, nid).then(()=> {
        setNeedsaved(true)
      })
      .catch(error => {
        // console.log(error)
        if(error.response && error.response.status === 409){
          setPublisherror('Need already exists for dates specified')
          return
        }

        if(error.response && error.response.status === 500){
          setPublisherror('Server responded with unknown error')
          return
        }        

        setPublisherror('Cannot reach server')
      })
      .finally(() => {
        setLoader(false)
      })
    }
  };



  const onChangeInput = (event, data) => {

    if(data.name === 'service' && data.value != null){
      setErrorservice(undefined)
    }

    if(data.name === 'minbudget'){
      if(data.value === ''){
        setErrorminbudget(notemptyError)
        return
      } 

      if(!DateUtils.isValidNumber(data.value)){
        setErrorminbudget(numberonly)
        return
      }       

      setErrorminbudget(undefined)

      if(DateUtils.isValidNumber(need.maxbudget) && parseInt(data.value, 10) >= need.maxbudget ){
        setErrormaxbudget(undefined)
      }
    } 

    if(data.name === 'maxbudget'){
      if(data.value === ''){
        setErrormaxbudget(notemptyError)
        return
      }       

      if(!DateUtils.isValidNumber(data.value)){
        setErrormaxbudget(numberonly)
        return
      }             

      if(data.value !== '' && !DateUtils.isValidNumber(data.value)){
        setErrormaxbudget(numberonly)
        return
      }             
      setErrormaxbudget(undefined)

      if(DateUtils.isValidNumber(need.minbudget) && parseInt(data.value, 10) >= need.minbudget ){
        setErrorminbudget(undefined)
      }      
    }    


    // FROM date period
    if(data.name === 'from'){
      if(data.value === ''){
        setErrorfrom(notemptyError)
        return
      } 

      if(!DateUtils.dateIsValid( data.value) ){
        setErrorfrom(notvaliddate)
        return
      }       


      if(DateUtils.dateIsPast  ( data.value) ){
        setErrorfrom(pastdate)
        return
      }       


      if(need.to!=null && DateUtils.dateIsValid(need.to) && DateUtils.dateIsValid(data.value) && DateUtils.getTimestamp(need.to) < DateUtils.getTimestamp(data.value)){
        setErrorto(notvaliddateperiod)
        setErrorfrom(notvaliddateperiod)      
        // return
      }      

      if(need.to!=null && DateUtils.dateIsValid(need.to) && DateUtils.dateIsValid(data.value) && DateUtils.getTimestamp(need.to) >= DateUtils.getTimestamp(data.value)){
        setErrorto(undefined)
        setErrorfrom(undefined)      
        
      }            

      setErrorfrom(undefined)
    }    
    
    
    // TO date period    
    if(data.name === 'to'){
      if(data.value === ''){
        setErrorto(notemptyError)
        return
      } 

      if(!DateUtils.dateIsValid( data.value) ){
        setErrorto(notvaliddate)
        return
      }      
      

      if(DateUtils.dateIsPast  ( data.value) ){
        setErrorto(pastdate)
        return
      }       



      if(need.from!=null && DateUtils.dateIsValid(data.value) && DateUtils.dateIsValid(need.from) && DateUtils.getTimestamp(data.value) < DateUtils.getTimestamp(need.from)){
        setErrorto(notvaliddateperiod)
        setErrorfrom(notvaliddateperiod)      
        // return
      }            

      if(need.from!=null && DateUtils.dateIsValid(data.value) && DateUtils.dateIsValid(need.from) && DateUtils.getTimestamp(data.value) >= DateUtils.getTimestamp(need.from)){
        setErrorto(undefined)
        setErrorfrom(undefined)      
      }                  
      
      setErrorto(undefined)
    }        

    dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
  }



  // console.log('myneed',need)
  console.log('hhh', history)
  return (

    <div>
        <h4>Fill in your need form </h4>


        {needsaved? 
          <Message icon success>
             <Icon name='warning'  />
             <Message.Content>
            <Message.Header>
              Your need has been saved successfully!
            </Message.Header>
            <p>
              Navigate to <b>My Packages</b> to see your needs
            </p>
            </Message.Content>
          </Message>
          :
          <></>
        }              


      <Form 
        onSubmit={handleSubmit} 
        loading={loader} 
        error={haserrors() || publisherror}
        >

        {  services &&
          <Form.Select 
            placeholder='Select Needed service' 
            width={5}
            required 

            name='service'
            label={nid?'Needed service':'Select Needed service' }
            defaultValue={'mykey'} 
            
            options={services}  
            onChange={onChangeInput} 
            error={errorservice}
          />
        }        



          <Form.Input className='required'
            width={5} 
            label='Min budget'
            defaultValue={need.minbudget} 
            placeholder='Minimun' 
            name='minbudget'
            onChange={onChangeInput} 
            error={errorminbudget}
          />

          <Form.Input className='required'
            width={5}
            label='Max budget'
            defaultValue={need.maxbudget} 
            placeholder='Maximum' 
            name='maxbudget'
            onChange={onChangeInput} 
            error={errormaxbudget}
          />


          <Form.Input className='required'
            width={5} 
            label='Period from'
            defaultValue={need.from} 
            placeholder='DD-MM-YYYY' 
            name='from'
            onChange={onChangeInput} 
            error={errorfrom}
          />

          <Form.Input className='required'
            width={5} 
            label='Period to'
            defaultValue={need.to} 
            placeholder='DD-MM-YYYY' 
            name='to'
            onChange={onChangeInput} 
            error={errorto}
          />

        {status !== undefined &&
          <Form.Group inline>
          <label className='required'>{status?'Active':'Disabled'}</label>
            <Form.Radio 
              toggle
              value={'on'}
              color={'green'}
              checked={status}
              name='active'
              onChange={()=> {

                if(!status && DateUtils.dateIsPast(need.to) ){
                  setErrorto(pastdate)
                  return
                }                                             
                setStatus(!status)

                dispatch({type:UPDATE_STATE, name: 'active', value: !need.active});
              }}
            />
            </Form.Group>
        }

          <Message
           error
           icon='warning'
           header='Cannot publish need!'
           content={publisherror?publisherror:'Form contains errors'}
          />


          <Button type='submit' disabled={haserrors()}> Submit </Button>

          {nid &&
              <Modal 
                  closeIcon
                  open={open}
                  trigger={<Button type='button' style={{marginLeft:'13em'}} color='red'>Delete</Button>}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}>
                
                <Header icon='warning' content='Delete need' />

                <Modal.Content>
                  <p> Are you sure you want to delete this need ? </p>
                </Modal.Content>
                
                <Modal.Actions>
                  <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> No
                  </Button>

                  <Button color='green' onClick={() => {
                      setLoader(true)
                      userService.deleteMyOffer(pid, nid).then(()=> {

                      history.goBack();
                      })

                      setOpen(false)
                    }}>
                    <Icon name='checkmark' /> Yes
                  </Button>
                </Modal.Actions>
              </Modal>        
            }          
      </Form>




    </div>
    )
};

export default ContractorNeedForm;