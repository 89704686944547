import {  Table, Button, Icon } from "semantic-ui-react";
import {  NavLink } from 'react-router-dom'
import {   useHistory } from 'react-router-dom'

const ContractorOfferTable = (props) => {
    console.log('ContractorOfferTable')

    const offers = props.offers.publish ? props.offers.publish : props.offers
    // console.log('oooo', offers.packagenam)
    const packagename = props.packagename?props.packagename:props.offers.packagename
    const packageexpired=props.packageexpired
    const id = props.id

    const navigateto = (nid) => {
        //console.log(nid) 
        history.push(`/packages/${id}/mypublish/${nid}`)
      }

      const history = useHistory()

      // console.log('***',offers)
    return (

      <div style={{marginLeft:'2em', marginRight:'2em'}}>
      

        <h3>My published offers for <b>{packagename}</b></h3>
    
        <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Created on</Table.HeaderCell>
                  <Table.HeaderCell>Country</Table.HeaderCell>
                  <Table.HeaderCell>Region</Table.HeaderCell>              

                  <Table.HeaderCell>Service</Table.HeaderCell>
    
                  <Table.HeaderCell>Cost</Table.HeaderCell>
                  <Table.HeaderCell>Period From</Table.HeaderCell>
                  <Table.HeaderCell>Period To</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
    
                </Table.Row>
              </Table.Header>
    
              <Table.Body >
                {offers.map(o => { 
                  return (
                <Table.Row style={{cursor:'pointer'}}>
                <Table.Cell  >{o.creationDate}</Table.Cell>
                <Table.Cell>{o.country}</Table.Cell>                            
                <Table.Cell>{o.region==null?'All regions':o.region}</Table.Cell>
                <Table.Cell>{o.service}</Table.Cell>
                <Table.Cell>{o.cost.toLocaleString('en-US')}</Table.Cell>

                <Table.Cell>{o.from}</Table.Cell>              
                <Table.Cell>{o.to}</Table.Cell>              
                <Table.Cell style={ {color:o.active?'green':'red'}}>{o.active?'ACTIVE':'DISABLED'}</Table.Cell>
                <Table.Cell>  
                  <Button disabled={packageexpired} onClick={()=>navigateto(o.id)} basic color='black'>View</Button>

                  <Button disabled={packageexpired} animated basic color='black'>
                    <Button.Content visible>Edit</Button.Content>
                    <Button.Content as={NavLink} to={`/packages/${id}/mypublish/${o.id}/edit`} hidden>
                      <Icon name='edit' />
                    </Button.Content>
                  </Button>                
                </Table.Cell>

              </Table.Row>              
              )
                } )}
                </Table.Body>
           </Table>            
      
        </div>        
    )
}

export default ContractorOfferTable;