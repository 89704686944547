
import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Message, Modal, Icon, Header, Dimmer, Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import SegmentOverview from "./modules/SegmentOverview";
import SegmentSocial from "./modules/SegmentSocial";
import SegmentContact from "./modules/SegmentContact";

const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {
  switch (event.type) {
    case FETCH_STATE:
      return event.payload;
    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }
    default:
      return profile;
  }  
 }

 const initialState = {};


const ProfileCompanyForm = () => {
  console.log('RENDER ProfileCompanyForm')
  
  const [profile, dispatch] = useReducer(formReducer, initialState);
  const [profileloader, setProfileloader] = useState(false)
  const [user, setUser] = useState()
  const [loader, setLoader] = useState(false)
  const [industries, setIndustries] = useState([])
  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])
  const [segmentoverviewerror, setSegmentoverviewerror] = useState(false)
  const [errorname, setErrorname]= useState()
  const [errorceo, setErrorceo]= useState()

  const [errorlogo, setErrorlogo] = useState(false)
  const [errorindustry, setErrorindustry] = useState(false)
  const [errorcountry, setErrorcountry] = useState(false)
  const [errorregion, setErrorregion] = useState(false)
  const [errorcity, setErrorcity] = useState(false)
  const [errorabout, setErrorabout] = useState(false)
  
  const [errorcontact, setErrorcontact] = useState(false)  
  const [erroremail, setErroremail] = useState(false)  
  const [errorfixed, setErrorfixed] = useState(false)  
  const [errormobile, setErrormobile] = useState(false)        

  const [success, setSuccess] = useState(false)
  const [open, setOpen] = useState()



  const handleSubmit = (e) => {
    e.preventDefault();

    const notemptyError = {content: 'Cannot be empty', pointing: 'below'}
    let errors = false

    if(profile.logo==null){
      setErrorlogo(notemptyError)
      errors = true
    }


    if(profile.industry.length===0){
      setErrorindustry(notemptyError)      
      errors = true
    }

    if(profile.ceo==null){
      setErrorceo(notemptyError)
      errors = true
    }    


    if(profile.country==null){
      setErrorcountry(notemptyError)
      errors = true
    }    

    if(profile.region==null){
      setErrorregion(notemptyError)
      errors = true
    }        


    if(profile.city==null){
      setErrorcity(notemptyError)
      errors = true
    }            

    if(profile.history==null){
      setErrorabout(notemptyError)
      errors = true
    }                

    if(profile.contactperson==null){
      setErrorcontact(notemptyError)
      errors = true
    }                
    
    if(profile.contactemail==null){
      setErroremail(notemptyError)
      errors = true
    }                
    
    if(profile.contactfixednumber==null){
      setErrorfixed(notemptyError)
      errors = true
    }                
    
    if(profile.contactmobilenumber==null){
      setErrormobile(notemptyError)
      errors = true
    }                    

    
    if(errors){
      return
    }

    //****************** */
    setLoader(true)
    userService.updateCustomerProfile(profile).then(()=> {
      setLoader(false)
      setSuccess(true)
      setOpen(true)
    })
    .catch(error => {
      // console.log(error.response)
            history.push("/error500");
    })    

  };

  const history = useHistory();


  const haserrors = () => segmentoverviewerror || errorlogo || errorindustry || errorabout || errorceo ||
                      errorcountry || errorregion || errorcity || 
                      errorcontact || errorfixed || errormobile || erroremail



  useEffect(() => {
    // console.log('EFFECT ProfileCompanyForm START')
    //setFetchloader(true)
    setProfileloader(true)
    
    const myuser = authService.getCurrentUser();
    setUser(myuser)

    if (myuser) {
      userService.getCustomerProfile().then((response) => {
        
        const myprofile = response.data;
        console.log(myprofile)
        userService.getIndustries().then((response)=> setIndustries(response.data))

        userService.getCountries().then((response)=> {
          setCountries(response.data)
          if(myprofile.country)
          userService.getRegions(myprofile.country).then((response)=> setRegions(response.data))          
        });
    
        // if(profile && profile.country){
        //   userService.getRegions(profile.country).then((response)=> setRegions(response.data))   
        // }    

        dispatch({ type:FETCH_STATE, payload: response.data });
      })
      .catch(error => {
        // console.log(error.response)
        if(error.response.status === 401){
          authService.logout();
          history.push("/login");
          window.location.reload();
        } else {
              history.push("/error500");
        }
      })
      .finally(()=> {  setProfileloader(false)})
    }
    // console.log('EFFECT ProfileCompanyForm END')
  }, []);

  

  return   (
    
    <Container style={{ paddingBottom: '5em' }} text>

    {profileloader &&
      <Dimmer active inverted>
        <Loader inverted>Loading profile</Loader>
      </Dimmer>
     }      


    {profile.customerid ?
      <Form 
        onSubmit={handleSubmit} 
        loading={loader || !profile.customerid}
        success={success}
        error={haserrors()}
        >


      <SegmentOverview
        profile={profile}
        user={user}
        dispatch={dispatch}

        filetype={'Logo'}
        industries={industries}
        countries={countries}
        regions={regions}
        setRegions={setRegions}

        setSegmentoverviewerror={setSegmentoverviewerror}
        errorname = {errorname}
        setErrorname = {setErrorname}
        errorceo = {errorceo}
        setErrorceo = {setErrorceo}        
        errorlogo={errorlogo}
        setErrorlogo={setErrorlogo}
        errorindustry={errorindustry}
        setErrorindustry={setErrorindustry}
        errorcountry={errorcountry}
        setErrorcountry={setErrorcountry}
        errorregion={errorregion}
        setErrorregion={setErrorregion}
        errorcity={errorcity}
        setErrorcity={setErrorcity}
        errorabout={errorabout}
        setErrorabout={setErrorabout}                        
      />

        
      <SegmentSocial 
        profile={profile}
        dispatch={dispatch}        


      />

      <SegmentContact 
        profile={profile}
        dispatch={dispatch}    

        errorcontact={errorcontact}
        setErrorcontact={setErrorcontact}
        erroremail={erroremail}
        setErroremail={setErroremail}
        errorfixed={errorfixed}
        setErrorfixed={setErrorfixed}
        errormobile={errormobile}
        setErrormobile={setErrormobile}
      />



        <Message
          success
          header='Profile saved successfully.'
          content="Go to 'My Needs' to publish your needs!"
        />        


        
              <Modal
                  closeIcon
                  open={open}
                  //trigger={<Button type='button' style={{marginLeft:'13em'}} color='red'>Delete</Button>}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}>
                
                <Header icon='check' content='Success!' />

                <Modal.Content>
                  <p> Your profile has been saved </p>
                </Modal.Content>
                
                <Modal.Actions>
                  <Button color='green' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> Ok
                  </Button>
                </Modal.Actions>
              </Modal>        
                                    

        
        <Message
           error
           icon='warning'
           header='Cannot save form!'
           content='Form contains errors'
        />
            
      <Button type='submit' disabled={haserrors()}> Save </Button>

      </Form>
      :<></>}
    
    </Container>
    ) 
  

  };

export default ProfileCompanyForm;