import { useEffect, useState } from "react";
import { Button,  Container, Form, Message } from "semantic-ui-react";
import {userService} from "../../services/user.service";
import {authService} from "../../services/auth.service";
import MyDropZone from "../publishmy/MyDropZone";

const Contact = () => {
    console.log('RENDER Contact')


    const [loader, setLoader] = useState()
    const [success, setSuccess] = useState()

    const [title, setTitle] = useState()
    const [body, setBody] = useState()
    // const [message, setMessage] = useState()
    const [files, setFiles] = useState([])



    //errors
    const [errortitle, setErrortitle] = useState()
    const [errorbody, setErrorbody] = useState()
    const [errormessage, setErrormessage] = useState()

    useEffect(() => {
        const user = authService.getCurrentUser();
    
        if (user) {
        //   userService.getCustomer(id).then((response) => setCustomer(response.data))
        }
      }, []);    


    const handleSubmit = (e) => {
        e.preventDefault();

        setErrormessage(false)
        setSuccess(false)
        let valid = true

        if(!title){
            setErrortitle('Title is empty')
            valid = false
        }

        if(!body){
            setErrorbody('Message is empty')
            valid = false
        }


        if(!valid){
            return
        }

        const formdata = new FormData()
        files.forEach(f => formdata.append("myphotos", f))

        // setMessage( {title, body})
        formdata.append("message",  new Blob([JSON.stringify({title, body})], {type : 'application/json'}))

        setLoader(true)
        userService.sendMessage(formdata).then((resp)=> {
          setSuccess(true)
          setLoader(false)
        })
        .catch(error => {
          // console.log(error)
          if(error.response && error.response.status === 500){
            setErrormessage('Server responded with error')
            return
          }        

          setErrormessage('Cannot reach server')
        })
        .finally(() => {
          setLoader(false)
        })        

    }


    // console.log('msg',message)
    // console.log('err', errormessage)

    return (
    <Container text>
        <h2>Contact Us</h2>

        <Form 
            onSubmit={handleSubmit} 
            loading={loader}
            success={success?true:false}
            error={errormessage?true:false}       
        >


        <Form.Input 
          label='Title' 
          name='title'
          error={errortitle}
          required
          placeholder='Message Title'
          onChange={(e,data)=>{
            if(data.value) {

                if(data.value.length > 100){
                  setErrortitle('Max 100 characters are allowed')    
                  return
                }
                setTitle(data.value);
                setErrortitle(undefined)
            } else {
              setErrortitle('Cannot be empty')
            }
        }} 
        />


        <Form.TextArea className='required'
          label='Message'

          placeholder='Your message here'
          name='message'
          error={ errorbody}
          onChange={(e,data) => {
              if(data.value){
                if(data.value.length > 1000){
                  setErrorbody('Message too long')    
                  return
                }                
                setBody(data.value)
                setErrorbody(undefined)
              } else {
                setErrorbody('Message cannot be empty')
              }
          }} 
        />



        <div>
          <div>
              <h5>Photos</h5>

              <MyDropZone 
                files={files}
                setFiles={setFiles}
              />

          </div>
          <div style={{marginTop:'0.5em'}} >&nbsp;</div>          
        </div>




        <Message
          success
          header='Thank you! Your message was sent successfully.'
        />        

        <Message
          error
          icon='warning'
          header='Cannot send message!'
          content={errormessage}
        />

        <Button type='submit' disabled={(errortitle || errorbody)?true:false}> Send </Button>

        </Form>        

    </Container>
    )
}

export default Contact;