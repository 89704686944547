import { Table } from "semantic-ui-react";

const OrgOfferHeadRow = (props) => {
    console.log('RENDER OrgOfferHeadRow')

    return (
        <Table.Row>
                  <Table.HeaderCell>Created on</Table.HeaderCell>
                  <Table.HeaderCell>Country</Table.HeaderCell>
                  <Table.HeaderCell>Region</Table.HeaderCell>              
                  <Table.HeaderCell>Cooperation model</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell>Tool</Table.HeaderCell>        
                  <Table.HeaderCell>Cost</Table.HeaderCell>                                          
                  <Table.HeaderCell>Period From</Table.HeaderCell>
                  <Table.HeaderCell>Period To</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
      </Table.Row>        
    )
}


export default OrgOfferHeadRow;